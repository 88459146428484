// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessPlan,
    AccessPlanFromJSON,
    AccessPlanToJSON,
} from './AccessPlan';
import {
    JsonUserInsurance,
    JsonUserInsuranceFromJSON,
    JsonUserInsuranceToJSON,
} from './JsonUserInsurance';
import {
    PermissionsEnum,
    PermissionsEnumFromJSON,
    PermissionsEnumToJSON,
} from './PermissionsEnum';
import {
    Pod,
    PodFromJSON,
    PodToJSON,
} from './Pod';
import {
    PodObjectDetails,
    PodObjectDetailsFromJSON,
    PodObjectDetailsToJSON,
} from './PodObjectDetails';


/**
 * 
 * @export
 * @interface JsonUser
 */
export interface JsonUser  {
    /**
     * 
     * @type {number}
     * @memberof JsonUser
     */
    id?: number;
    /**
     * 
     * @type {Array<AccessPlan>}
     * @memberof JsonUser
     */
    accessPlans?: Array<AccessPlan>;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    genderIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    sexualOrientation?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    pronouns?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    hearing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    vision?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    race?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    dob?: string;
    /**
     * 
     * @type {Date}
     * @memberof JsonUser
     */
    bornAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    clinicContact?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    clinicId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    xoid?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    insuranceId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    pcp?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    restrictedDependent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    oauthSignIn?: boolean;
    /**
     * 
     * @type {Array<PermissionsEnum>}
     * @memberof JsonUser
     */
    permissions?: Array<PermissionsEnum>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    admin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    ctm?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    ctc?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    consented?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    verified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    defaultSite?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    client?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    accountLocked?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    accountUnlockable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    patientId?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    workPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    homePhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    hasCreatedEoc?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonUser
     */
    serviceLines?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof JsonUser
     */
    becomesIneligibleAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    employer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    amatHra2021SurveyEligible?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonUser
     */
    efId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonUser
     */
    guilds?: Array<string>;
    /**
     * 
     * @type {Array<Pod>}
     * @memberof JsonUser
     */
    podFilter?: Array<Pod>;
    /**
     * 
     * @type {Array<PodObjectDetails>}
     * @memberof JsonUser
     */
    activePods?: Array<PodObjectDetails>;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    affinity?: JsonUserAffinityEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    logo?: JsonUserLogoEnum;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    isFirstTimeLogin?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonUser
     */
    successfulLogins?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    aetnaMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    planSponsorControlName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    state?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    hasExtCareTeam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    minor?: boolean;
    /**
     * if false, user is the primary account, otherwise it is a dependent
     * @type {boolean}
     * @memberof JsonUser
     */
    dependent?: boolean;
    /**
     * if true, user is the primary account, otherwise it is a dependent
     * @type {boolean}
     * @memberof JsonUser
     */
    primary?: boolean;
    /**
     * 
     * @type {JsonUserInsurance}
     * @memberof JsonUser
     */
    insurance?: JsonUserInsurance;
    /**
     * if true, show insurance onboarding widget otherwise hide it
     * @type {boolean}
     * @memberof JsonUser
     */
    insShowOnboarding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    relationshipCode?: string;
}

export function JsonUserFromJSON(json: any): JsonUser {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'accessPlans': !exists(json, 'access_plans') ? undefined : (json['access_plans'] as Array<any>).map(AccessPlanFromJSON),
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'fullName': !exists(json, 'full_name') ? undefined : json['full_name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'genderIdentity': !exists(json, 'gender_identity') ? undefined : json['gender_identity'],
        'sexualOrientation': !exists(json, 'sexual_orientation') ? undefined : json['sexual_orientation'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'hearing': !exists(json, 'hearing') ? undefined : json['hearing'],
        'vision': !exists(json, 'vision') ? undefined : json['vision'],
        'race': !exists(json, 'race') ? undefined : json['race'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'bornAt': !exists(json, 'born_at') ? undefined : new Date(json['born_at']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'clinicContact': !exists(json, 'clinic_contact') ? undefined : json['clinic_contact'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'xoid': !exists(json, 'xoid') ? undefined : json['xoid'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'insuranceId': !exists(json, 'insurance_id') ? undefined : json['insurance_id'],
        'pcp': !exists(json, 'pcp') ? undefined : json['pcp'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'restrictedDependent': !exists(json, 'restricted_dependent') ? undefined : json['restricted_dependent'],
        'oauthSignIn': !exists(json, 'oauth_sign_in') ? undefined : json['oauth_sign_in'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] as Array<any>).map(PermissionsEnumFromJSON),
        'admin': !exists(json, 'admin') ? undefined : json['admin'],
        'ctm': !exists(json, 'ctm') ? undefined : json['ctm'],
        'ctc': !exists(json, 'ctc') ? undefined : json['ctc'],
        'consented': !exists(json, 'consented') ? undefined : json['consented'],
        'verified': !exists(json, 'verified') ? undefined : json['verified'],
        'defaultSite': !exists(json, 'default_site') ? undefined : json['default_site'],
        'client': !exists(json, 'client') ? undefined : json['client'],
        'preferredLanguage': !exists(json, 'preferred_language') ? undefined : json['preferred_language'],
        'accountLocked': !exists(json, 'account_locked') ? undefined : json['account_locked'],
        'accountUnlockable': !exists(json, 'account_unlockable') ? undefined : json['account_unlockable'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'workPhone': !exists(json, 'work_phone') ? undefined : json['work_phone'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'hasCreatedEoc': !exists(json, 'has_created_eoc') ? undefined : json['has_created_eoc'],
        'serviceLines': !exists(json, 'service_lines') ? undefined : json['service_lines'],
        'becomesIneligibleAt': !exists(json, 'becomes_ineligible_at') ? undefined : new Date(json['becomes_ineligible_at']),
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'amatHra2021SurveyEligible': !exists(json, 'amat_hra_2021_survey_eligible') ? undefined : json['amat_hra_2021_survey_eligible'],
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'guilds': !exists(json, 'guilds') ? undefined : json['guilds'],
        'podFilter': !exists(json, 'pod_filter') ? undefined : (json['pod_filter'] as Array<any>).map(PodFromJSON),
        'activePods': !exists(json, 'active_pods') ? undefined : (json['active_pods'] as Array<any>).map(PodObjectDetailsFromJSON),
        'affinity': !exists(json, 'affinity') ? undefined : json['affinity'],
        'logo': !exists(json, 'logo') ? undefined : json['logo'],
        'isFirstTimeLogin': !exists(json, 'is_first_time_login') ? undefined : json['is_first_time_login'],
        'successfulLogins': !exists(json, 'successful_logins') ? undefined : json['successful_logins'],
        'aetnaMember': !exists(json, 'aetna_member') ? undefined : json['aetna_member'],
        'planSponsorControlName': !exists(json, 'plan_sponsor_control_name') ? undefined : json['plan_sponsor_control_name'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'hasExtCareTeam': !exists(json, 'has_ext_care_team') ? undefined : json['has_ext_care_team'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'dependent': !exists(json, 'dependent') ? undefined : json['dependent'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'insurance': !exists(json, 'insurance') ? undefined : JsonUserInsuranceFromJSON(json['insurance']),
        'insShowOnboarding': !exists(json, 'ins_show_onboarding') ? undefined : json['ins_show_onboarding'],
        'relationshipCode': !exists(json, 'relationship_code') ? undefined : json['relationship_code'],
    };
}

export function JsonUserToJSON(value?: JsonUser): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'access_plans': value.accessPlans === undefined ? undefined : (value.accessPlans as Array<any>).map(AccessPlanToJSON),
        'avatar_url': value.avatarUrl,
        'first_name': value.firstName,
        'preferred_name': value.preferredName,
        'legal_name': value.legalName,
        'last_name': value.lastName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'full_name': value.fullName,
        'gender': value.gender,
        'gender_identity': value.genderIdentity,
        'sexual_orientation': value.sexualOrientation,
        'pronouns': value.pronouns,
        'hearing': value.hearing,
        'vision': value.vision,
        'race': value.race,
        'ethnicity': value.ethnicity,
        'sex_at_birth': value.sexAtBirth,
        'dob': value.dob,
        'born_at': value.bornAt === undefined ? undefined : value.bornAt.toISOString().substr(0,10),
        'email': value.email,
        'clinic_contact': value.clinicContact,
        'clinic_id': value.clinicId,
        'xoid': value.xoid,
        'employee_id': value.employeeId,
        'insurance_id': value.insuranceId,
        'pcp': value.pcp,
        'status': value.status,
        'restricted_dependent': value.restrictedDependent,
        'oauth_sign_in': value.oauthSignIn,
        'permissions': value.permissions === undefined ? undefined : (value.permissions as Array<any>).map(PermissionsEnumToJSON),
        'admin': value.admin,
        'ctm': value.ctm,
        'ctc': value.ctc,
        'consented': value.consented,
        'verified': value.verified,
        'default_site': value.defaultSite,
        'client': value.client,
        'preferred_language': value.preferredLanguage,
        'account_locked': value.accountLocked,
        'account_unlockable': value.accountUnlockable,
        'patient_id': value.patientId,
        'cell_phone': value.cellPhone,
        'work_phone': value.workPhone,
        'home_phone': value.homePhone,
        'has_created_eoc': value.hasCreatedEoc,
        'service_lines': value.serviceLines,
        'becomes_ineligible_at': value.becomesIneligibleAt === undefined ? undefined : value.becomesIneligibleAt.toISOString().substr(0,10),
        'employer': value.employer,
        'amat_hra_2021_survey_eligible': value.amatHra2021SurveyEligible,
        'ef_id': value.efId,
        'guilds': value.guilds,
        'pod_filter': value.podFilter === undefined ? undefined : (value.podFilter as Array<any>).map(PodToJSON),
        'active_pods': value.activePods === undefined ? undefined : (value.activePods as Array<any>).map(PodObjectDetailsToJSON),
        'affinity': value.affinity,
        'logo': value.logo,
        'is_first_time_login': value.isFirstTimeLogin,
        'successful_logins': value.successfulLogins,
        'aetna_member': value.aetnaMember,
        'plan_sponsor_control_name': value.planSponsorControlName,
        'state': value.state,
        'has_ext_care_team': value.hasExtCareTeam,
        'minor': value.minor,
        'dependent': value.dependent,
        'primary': value.primary,
        'insurance': JsonUserInsuranceToJSON(value.insurance),
        'ins_show_onboarding': value.insShowOnboarding,
        'relationship_code': value.relationshipCode,
    };
}

/**
* @export
* @enum {string}
*/
export enum JsonUserAffinityEnum {
    Onsite = 'onsite',
    Nearsite = 'nearsite',
    Remote = 'remote',
    InpersonWithoutTravel = 'inperson_without_travel'
}
/**
* @export
* @enum {string}
*/
export enum JsonUserLogoEnum {
    Meta = 'meta',
    Xop = 'xop'
}


