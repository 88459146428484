import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { fetchAuth0Token } from "shared/features/auth/utils";
import LinearProgress from "components/Progress/LinearProgress";
import Container from "components/Container";
import { useLocation } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import Typography from "components/Typography";
import classes from "./index.module.css";
import Asset from "components/Asset";
import Logo from "styles/images/logo/logo.png";
import ButtonStyledAsLink from "components/Button/ButtonStyledAsLink";
import NativeApp from "models/NativeApp";

interface ILoginValidationErrorProps {
  errorDescription: string | undefined;
}

const LoginValidationErrorPage: FC<ILoginValidationErrorProps> = ({
  errorDescription,
}) => {
  const { logout } = useAuth0();
  return (
    <div data-e2e="login-validation-error-page">
      <div className={classes.appBar}>
        <img src={Logo} className={classes.logo} alt="Crossover Health" />
      </div>
      <div className={classes.container}>
        <div className={classes.textContainer} aria-live="assertive">
          <div>
            <Typography className={classes.errorMessage}>
              {errorDescription}
            </Typography>
            <ButtonStyledAsLink onClick={() => logout()}>
              Return to Login
            </ButtonStyledAsLink>
          </div>
          <Asset name="error-results" className={classes.image} />
        </div>
      </div>
    </div>
  );
};

const Display: FC<{ error: string }> = ({ error }) => {
  return (
    <div>
      <LinearProgress />
      <Container maxWidth="lg" style={{ padding: "16px" }}>
        {error && <p data-e2e="error-message">{error}</p>}
      </Container>
    </div>
  );
};

/**
 * Auth0 redirect route.
 */
const Auth0Redirect = () => {
  const [error, setError] = useState<string>("");
  const [isLoginValidationError, setIsLoginValidationError] =
    useState<boolean>(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { handleRedirectCallback, getAccessTokenSilently, logout } = useAuth0();
  const parsedQueryString = queryString.parse(location.search);

  const redirectAndFetchToken = () => {
    const handleRedirect = () =>
      handleRedirectCallback(location.pathname + location.search).then(() => {
        NativeApp.hideNativeButtons();
        return getAccessTokenSilently();
      });
    fetchAuth0Token(dispatch, handleRedirect, logout)
      .then(() => {
        window.location.assign(parsedQueryString?.next_url as string);
      })
      .catch((err: any) => {
        setError(err.message);
      });
  };

  useEffect(() => {
    if (parsedQueryString?.error_description) {
      setIsLoginValidationError(true);
    }
  }, []);
  useEffect(() => {
    if (!parsedQueryString?.error_description) {
      redirectAndFetchToken();
    } else {
      return;
    }
  }, []);

  return (
    <>
      {isLoginValidationError ? (
        <LoginValidationErrorPage
          errorDescription={parsedQueryString?.error_description?.toString()}
        />
      ) : (
        <Display error={error} />
      )}
    </>
  );
};

export default Auth0Redirect;
