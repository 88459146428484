import React from "react";
import { Heading } from "@tenon-io/tenon-ui";
import { UploadedDocument } from "components/CreateTimelineMessage";
import AttachmentThumbnail from "components/AttachmentThumbnail";
import Box from "components/Box";
import ElementList from "components/ElementList";

interface AttachmentPreviewProps {
  documents: UploadedDocument[];
  handleDetachFile?: (id: string) => void;
}
const AttachmentPreview: React.FunctionComponent<AttachmentPreviewProps> = ({
  documents,
  handleDetachFile,
}) => {
  if (documents.length === 0) {
    return null;
  } else {
    return (
      <>
        <Box
          paddingTop={2}
          paddingLeft={2}
          paddingBottom={1}
          className="attachment-header"
        >
          <Heading.H className="h6">{`${documents.length} Attachment${
            documents.length === 1 ? "" : "s"
          }:`}</Heading.H>
        </Box>
        <ElementList
          elements={documents}
          component={AttachmentThumbnail}
          restProps={{
            className: "attachment",
            onDetachFile: handleDetachFile,
          }}
        />
      </>
    );
  }
};

export default AttachmentPreview;
