import React, { FunctionComponent, useState } from "react";
import clsx from "clsx";
import SpaIcon from "@mui/icons-material/Spa";
import EocBadge from "components/EocBadge";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user/selectors";
import { useGetSelfScheduleAppointmentByEpisodeId } from "shared/features/components";
import { Email } from "@mui/icons-material";
import styles from "./index.module.css";
import { CssTypography as Typography } from "../../CssTypography";
import Heading from "components/DynamicHeadings";
import EOCVisitDetailsPopover from "components/EOCLineItemTable/EOCVisitDetailsPopover";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";
import { EpisodeSummaryCoreOutputInitialRecipientTypeEnum } from "shared/fetch/src/models/EpisodeSummaryCoreOutput";
import { RoleOutputCoreSchema } from "shared/fetch/src/models/RoleOutputCoreSchema";

interface IProps {
  reason: string;
  duration: string;
  lastInteraction: string;
  isArchived?: boolean;
  guilds?: string[];
  roles?: RoleOutputCoreSchema[];
  owner?: CTMInfo;
  toggleModal?: Function;
  hasDirectMessage?: boolean;
  initialRecipientType?: EpisodeSummaryCoreOutputInitialRecipientTypeEnum;
  displayPopoverOnHover?: boolean;
  id: string;
}

const EOCMember: FunctionComponent<IProps> = ({
  reason,
  duration,
  lastInteraction,
  isArchived,
  guilds,
  roles,
  toggleModal,
  owner,
  hasDirectMessage,
  initialRecipientType,
  displayPopoverOnHover,
  id,
}) => {
  const { data, refetch: fetchSSDetails } = useGetSelfScheduleAppointmentByEpisodeId({ id });

  const classes = styles;
  const user = useSelector(getUser);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isPreventiveCare =
    reason === "Preventive care" || reason === "Preventive";
  const MAX_ROLES_DISPLAYED = 4;
  const displayedRoles = (roles || []).slice(0, MAX_ROLES_DISPLAYED);
  const ownerName =
    (user?.id?.toString() === owner?.id?.toString()
      ? "You"
      : owner?.truncatedName) || "None";

  const toggleOwnerModal = (e: any) => {
    e.stopPropagation();
    // @ts-ignore: Object is possibly 'null'.
    toggleModal();
  };

  const handleOpenPopover = (event: any) => {
    // @ts-ignore: Object is possibly 'null'.
    event.stopPropagation();
    fetchSSDetails()
    // @ts-ignore: Object is possibly 'null'.
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <section className={classes.eocMember}>
        <Heading.LevelBoundary>
          <Heading.H
            appearance="smallBody"
            data-test="reason-for-episode"
            fontWeightBold
            gutterBottom
            cssType
          >
            <div className={classes.titleContainer}>
              <div className={classes.reason}>
                {displayPopoverOnHover ? "Visit " : ""}
                {initialRecipientType ===
                EpisodeSummaryCoreOutputInitialRecipientTypeEnum.CareTeam
                  ? "General message"
                  : reason}{" "}
              </div>
              {isPreventiveCare && (
                <span className={classes.spaSpan}>
                  <SpaIcon fontSize="small" titleAccess="Preventive episode" />
                </span>
              )}
              {hasDirectMessage && (
                <Email className={classes.memberMessageIcon} />
              )}
              {displayedRoles?.map((role) => (
                <div key={role?.abbreviation} className={classes.badges}>
                  <EocBadge
                    key={`badge_${role?.abbreviation}`}
                    className={classes.marginBottom0}
                    badge={{
                      abbreviation: role?.abbreviation ?? "",
                      color: role?.color ?? "",
                      backgroundColor: role?.backgroundColor ?? "",
                    }}
                  />
                </div>
              ))}
            </div>
          </Heading.H>
        </Heading.LevelBoundary>

        {isArchived ? (
          <>
            <Typography
              appearance="smallBody"
              className={clsx(classes.marginBottom4, classes.textSecondary)}
            >
              {`Archived by: ${lastInteraction}`}
            </Typography>

            {guilds?.map((role) => (
              <Typography
                key={`guild_${role}`}
                appearance="smallBody"
                className={clsx(classes.marginBottom4, classes.textSecondary)}
              >
                <span>{role}</span>
              </Typography>
            ))}
          </>
        ) : (
          <>
            {displayPopoverOnHover && (
              <>
                <Typography
                  appearance="smallBody"
                  className={classes.hoverableText}
                  onMouseEnter={handleOpenPopover}
                  onMouseLeave={handleClosePopover}
                  aria-owns={
                    Boolean(anchorEl) ? "visit-details-popover" : undefined
                  }
                  aria-haspopup="true"
                >
                  Visit Details
                </Typography>
                <EOCVisitDetailsPopover
                  anchorEl={anchorEl}
                  onMouseLeave={handleClosePopover}
                  visit={data}
                />
              </>
            )}
            {!displayPopoverOnHover && (
              <>
                <Typography
                  appearance="smallBody"
                  className={clsx(classes.marginBottom4, classes.textSecondary)}
                >
                  Duration: {duration}
                </Typography>
                <Typography
                  appearance="smallBody"
                  className={clsx(classes.marginBottom4, classes.textSecondary)}
                >
                  Last: {lastInteraction ? lastInteraction : "None"}
                </Typography>
                <div className={classes.ownerContainer}>
                  <Typography
                    appearance="smallBody"
                    className={clsx(
                      classes.marginBottom4,
                      classes.textSecondary,
                      classes.owner
                    )}
                  >
                    Owner:
                  </Typography>
                  <Typography
                    className={classes.selectOwnerButton}
                    onClick={toggleOwnerModal}
                    data-e2e={`select-owner-button`}
                    role="button"
                    appearance="smallBody"
                  >
                    {ownerName}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};

export default EOCMember;
