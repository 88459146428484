import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMatch, useLocation, useNavigate } from "react-router";
import { ButtonBase, Drawer, Link, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import { State } from "@types";

import { hideMobileComponentModal } from "shared/state/ui/mobileComponentModal";
import useDraftSafeHandler from "hooks/useDraftSafeHandler";

import IconButton from "components/Button/IconButton";
import LinearProgress from "components/Progress/LinearProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "#FFFFFF",
      borderRadius: "20px 20px 0px 0px",
      width: "100%",
      height: (props: any) => props?.height,
    },
    container: {
      display: "flex",
    },
    closeIconContainer: {
      display: "flex",
      justifyContent: "flex-end",
    },
    titleContainer: {
      display: "flex",
      justifyContent: "flex-start",
      flexGrow: 1,
      paddingTop: 20,
      paddingBottom: 20,
    },
    title: {
      textAlign: "center",
      alignSelf: "center",
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      fontSize: theme?.typography?.body?.fontSize,
      fontWeight: "bold",
      color: theme?.palette?.text?.primary,
    },
    closeButton: {
      paddingLeft: 16,
      fontSize: theme?.typography?.body?.fontSize,
      textDecoration: "underline",
      color: theme?.palette?.secondary?.main,
    },
    progressBar: {
      paddingBottom: theme?.spacing?.(2),
    },
  })
);
export default function MobileComponentModal() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const lastRoute = useRef<any>("/");
  const currentRoute = useRef<any>("/");
  const closeButtonRef = useRef(null);
  const [label, setLabel] = useState("");

  const {
    open,
    fullScreen,
    height,
    onClose,
    title,
    content,
    routeComponent,
    progressBarLevel,
  } = useSelector((state: State) => state.ui.mobileComponentModal);

  useEffect(() => {
    if (title) {
      // @ts-ignore
      setLabel(title);
    }
  }, [title]);

  useEffect(() => {
    currentRoute.current = location.pathname;
  }, []);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (open) {
      timeoutId = setTimeout(() => {
        // @ts-ignore
        closeButtonRef?.current?.focus();
      }, 0);
    }

    return () => clearTimeout(timeoutId);
  }, [open]);

  useEffect(() => {
    if (currentRoute.current !== location.pathname && !open) {
      lastRoute.current = currentRoute.current;
      currentRoute.current = location.pathname;
    }
  }, [location.pathname]);

  const onCloseHandler = useDraftSafeHandler(() => {
    onClose?.();
    dispatch(hideMobileComponentModal());
    routeComponent && navigate(lastRoute.current);
  });

  const classes = useStyles({ height });

  const contentPath: string = (content as React.ReactElement)?.props?.children
    ?.props?.match?.pathname;
  const isMatch = useMatch(contentPath ?? "");

  if (routeComponent && !location.pathname.includes("get-care")) {
    if (!isMatch) {
      dispatch(hideMobileComponentModal());
    }
  }

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onCloseHandler}
      classes={{ paper: classes.paper }}
      role="dialog"
      aria-labelledby="modal-title"
      id="mobile-component-modal"
    >
      <div className={classes.container}>
        {fullScreen ? (
          <div className={classes.closeIconContainer}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onCloseHandler}
              aria-label="close"
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </div>
        ) : (
          <div className={classes.titleContainer}>
            <div className={classes.closeButton}>
              <ButtonBase
                component={Link}
                onClick={onCloseHandler}
                ref={closeButtonRef}
                aria-label={`Close ${label} Dialog`}
              >
                Close
              </ButtonBase>
            </div>
            <h2 id="modal-title" className={classes.title}>
              {title}
            </h2>
          </div>
        )}
      </div>
      {progressBarLevel && (
        <div className={classes.progressBar}>
          <LinearProgress
            variant="determinate"
            value={progressBarLevel}
            data-testid="progress-bar"
          />
        </div>
      )}
      <div>{routeComponent ? <div>{content}</div> : content}</div>
    </Drawer>
  );
}
