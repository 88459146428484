// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmployersResponseResults
 */
export interface EmployersResponseResults  {
    /**
     * 
     * @type {string}
     * @memberof EmployersResponseResults
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmployersResponseResults
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmployersResponseResults
     */
    aetnaPlanSponsor?: boolean;
}

export function EmployersResponseResultsFromJSON(json: any): EmployersResponseResults {
    return {
        'displayName': !exists(json, 'display_name') ? undefined : json['display_name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'aetnaPlanSponsor': !exists(json, 'aetna_plan_sponsor') ? undefined : json['aetna_plan_sponsor'],
    };
}

export function EmployersResponseResultsToJSON(value?: EmployersResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'display_name': value.displayName,
        'value': value.value,
        'aetna_plan_sponsor': value.aetnaPlanSponsor,
    };
}


