import React, { FunctionComponent } from "react";
import clsx from "clsx";

import Icons from "../../styles/icons/icons.svg";
import getSvgName, { imageMimeTypes } from "utils/getSvgName";
import AuthorizedImage from "components/AuthorizedImage";
import Badge from "components/Badge";
import Box from "components/Box";
import Button from "components/Button";
import useIconStyles from "utils/useIconStyles";

interface AttachmentThumbnailProps {
  thumbnailURL?: string;
  restProps?: any;
  id: string;
  mime: string;
  name?: string;
}

const AttachmentThumbnail: FunctionComponent<AttachmentThumbnailProps> = ({
  thumbnailURL,
  restProps,
  id,
  mime,
  name,
}): JSX.Element => {
  const mimeType = mime && mime.split("/")[1];
  const svgName = getSvgName(mime);

  const DeleteAttachmentButton: FunctionComponent = () => {
    const iconStyles = useIconStyles();
    return (
      <Button
        color="tertiary"
        aria-label={`Delete attachment file ${name}`}
        className="delete-attachment-button"
      >
        <svg
          data-e2e="delete-attachment"
          className={clsx("icon", "icon-close-attachment", iconStyles.icon)}
          onClick={() => restProps.onDetachFile(id)}
        >
          <use xlinkHref={`${Icons}#icon-close-attachment`} />
        </svg>
      </Button>
    );
  };

  return (
    <Box component="span" padding={2} className="attachment-badge-container">
      <Badge
        badgeContent={<DeleteAttachmentButton />}
        style={{ background: "green" }}
        className="attachment-badge"
      >
        <AuthorizedImage
          data-e2e="attachment-thumbnail"
          src={
            imageMimeTypes.includes(mimeType)
              ? thumbnailURL
              : require(`../../styles/icons/non-image-preview-icons/${svgName}.svg`)
                  .default
          }
          alt={restProps.alt}
          className={restProps.className}
        />
      </Badge>
    </Box>
  );
};

export default AttachmentThumbnail;
