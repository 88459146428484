// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from './TimelineEventOutput';


/**
 * 
 * @export
 * @interface TimelineEpisodeOutputAllOf
 */
export interface TimelineEpisodeOutputAllOf  {
    /**
     * 
     * @type {{ [key: string]: Array<ActionableItemSummary>; }}
     * @memberof TimelineEpisodeOutputAllOf
     */
    actionableItems?: { [key: string]: Array<ActionableItemSummary>; };
    /**
     * 
     * @type {string}
     * @memberof TimelineEpisodeOutputAllOf
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEpisodeOutputAllOf
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof TimelineEpisodeOutputAllOf
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineEpisodeOutputAllOf
     */
    last?: number;
    /**
     * 
     * @type {Array<TimelineEventOutput>}
     * @memberof TimelineEpisodeOutputAllOf
     */
    timelineEvents: Array<TimelineEventOutput>;
    /**
     * 
     * @type {string}
     * @memberof TimelineEpisodeOutputAllOf
     */
    urgentPhoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TimelineEpisodeOutputAllOf
     */
    billable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimelineEpisodeOutputAllOf
     */
    consultation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimelineEpisodeOutputAllOf
     */
    promptConsultation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TimelineEpisodeOutputAllOf
     */
    consultationSystemGenerated?: boolean;
    /**
     * 
     * @type {Array<CTMInfo>}
     * @memberof TimelineEpisodeOutputAllOf
     */
    careTeamParticipants?: Array<CTMInfo>;
    /**
     * 
     * @type {MemberInfo}
     * @memberof TimelineEpisodeOutputAllOf
     */
    lastMemberUpdater?: MemberInfo;
    /**
     * 
     * @type {CTMInfo}
     * @memberof TimelineEpisodeOutputAllOf
     */
    lastCtmUpdater?: CTMInfo;
}

export function TimelineEpisodeOutputAllOfFromJSON(json: any): TimelineEpisodeOutputAllOf {
    return {
        'actionableItems': !exists(json, 'actionable_items') ? undefined : json['actionable_items'],
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'timelineEvents': (json['timeline_events'] as Array<any>).map(TimelineEventOutputFromJSON),
        'urgentPhoneNumber': !exists(json, 'urgent_phone_number') ? undefined : json['urgent_phone_number'],
        'billable': !exists(json, 'billable') ? undefined : json['billable'],
        'consultation': !exists(json, 'consultation') ? undefined : json['consultation'],
        'promptConsultation': !exists(json, 'prompt_consultation') ? undefined : json['prompt_consultation'],
        'consultationSystemGenerated': !exists(json, 'consultation_system_generated') ? undefined : json['consultation_system_generated'],
        'careTeamParticipants': !exists(json, 'care_team_participants') ? undefined : (json['care_team_participants'] as Array<any>).map(CTMInfoFromJSON),
        'lastMemberUpdater': !exists(json, 'last_member_updater') ? undefined : MemberInfoFromJSON(json['last_member_updater']),
        'lastCtmUpdater': !exists(json, 'last_ctm_updater') ? undefined : CTMInfoFromJSON(json['last_ctm_updater']),
    };
}

export function TimelineEpisodeOutputAllOfToJSON(value?: TimelineEpisodeOutputAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'actionable_items': value.actionableItems,
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'timeline_events': (value.timelineEvents as Array<any>).map(TimelineEventOutputToJSON),
        'urgent_phone_number': value.urgentPhoneNumber,
        'billable': value.billable,
        'consultation': value.consultation,
        'prompt_consultation': value.promptConsultation,
        'consultation_system_generated': value.consultationSystemGenerated,
        'care_team_participants': value.careTeamParticipants === undefined ? undefined : (value.careTeamParticipants as Array<any>).map(CTMInfoToJSON),
        'last_member_updater': MemberInfoToJSON(value.lastMemberUpdater),
        'last_ctm_updater': CTMInfoToJSON(value.lastCtmUpdater),
    };
}


