const getSvgName = (mimeType: string) => {
  const mime = mimeType && mimeType.split("/")[1];
  const microsoftOfficeMimeTypes = [
    "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "vnd.openxmlformats-officedocument.wordprocessingml.document",
    "vnd.openxmlformats-officedocument.presentationml.presentation",
    "msword",
    "vnd.ms-excel",
    "vnd.ms-powerpoint",
    "image/svg+xml",
  ];

  const IOSMimeTypes = [
    "x-iwork-keynote-sffkey",
    "x-iwork-pages-sffpages",
    "x-iwork-numbers-sffnumbers",
  ];

  let splitNameArray;
  if (microsoftOfficeMimeTypes.includes(mime)) {
    splitNameArray = mime.split(".");
    return splitNameArray[splitNameArray.length - 1];
  } else if (IOSMimeTypes.includes(mime)) {
    splitNameArray = mime.split("-");
    return splitNameArray[splitNameArray.length - 2];
  } else {
    return mime;
  }
};

export const imageMimeTypes = ["gif", "png", "jpeg", "svg+xml"];

export default getSvgName;
