import React, { FunctionComponent } from "react";
import clsx from "clsx";
import User from "models/User";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import useFormatDate from "shared/utils/useFormatDate";
import EligibilityMessaging from "../EligibilityMessaging";
import { CallSplit } from "@mui/icons-material";
import styles from "./MemberIdCard.module.css";
import { CssTypography as Typography } from "../CssTypography";
import Heading from "components/DynamicHeadings";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import Box from "components/Box";
import { MemberInfo } from "shared/fetch/src/models/MemberInfo";
import { CTMInfoProps } from "shared/fetch/src/models/CTMInfoProps";

export type LabelAppearance = "body" | "smallBody";

interface IProps {
  member: MemberInfo;
  labelAppearance: LabelAppearance;
  tertiary?: boolean;
  showEligibilityMessaging?: boolean;
  assignedPcp?: CTMInfoProps;
  size?: "small" | "medium" | "large";
  cctAssigned?: boolean;
}

const MemberIdCard: FunctionComponent<IProps> = ({
  member,
  tertiary,
  labelAppearance,
  showEligibilityMessaging,
  size,
  cctAssigned,
}) => {
  const classes = styles;
  const formatDate = useFormatDate();
  const bodyClass = clsx(classes.bodyText, classes?.[size!] || "");
  const cctName = (member as any)?.corePodName || "None"; // cast to any for now as work is in progress to add this on BE
  const textDisplay = tertiary ? classes.tertiary : classes.underline;
  const displayName = member.legalAndPreferredName;
  const currentUser = useSelector(getUser);
  const pendingIneligibleMessage = `Act ineligible in ${User.daysUntilIneligible(
    member
  )}.`;

  let pcpName;
  if (!member?.assignedPcp) {
    pcpName = "None";
  } else if (member?.assignedPcp?.firstName === currentUser?.firstName) {
    pcpName = "You";
  } else {
    const { truncatedName } = member?.assignedPcp as CTMInfoProps;

    pcpName = truncatedName;
  }

  const { accountInfo, isPending } =
    useGetAccountInfo({
      id: member?.id,
    }) || {};

  return (
    <Box
      data-e2e="member-details"
      data-testid="member-details"
      className={classes.container}
      component="dl"
    >
      <Box className="sr-only" component="dt">
        Name:{" "}
      </Box>
      <Box component="dd">
        <Heading.H
          fontWeightBold
          test-id="test-1234"
          aria-label="Creator Display Name"
          appearance={labelAppearance}
          cssType
          className={clsx(
            classes.headingText,
            member?.type === "member" && textDisplay,
            classes?.[size!] || ""
          )}
        >
          {displayName}{" "}
          {!isPending &&
            !!accountInfo?.activeEpisodeCount &&
            `(${accountInfo?.activeEpisodeCount})`}
        </Heading.H>
      </Box>
      {member?.employer && member?.state && (
        <>
          <Box className="sr-only" component="dt">
            From:{" "}
          </Box>
          <Box className={styles.employer} component="dd" lineHeight="1.3rem">
            <Typography
              appearance={labelAppearance}
              className={bodyClass}
              data-testid="member-details-employer-and-state"
            >
              {`${member.employer}${member.state && ", " + member.state}`}
            </Typography>
          </Box>
        </>
      )}
      <Box component="dt" display="inline">
        <Typography appearance={labelAppearance} className={bodyClass}>
          DOB:{" "}
        </Typography>
      </Box>
      <Box component="dd" display="inline">
        <Typography
          appearance={labelAppearance}
          className={bodyClass}
          data-e2e="member-details-dob"
        >
          {formatDate(member.dob, "MM/dd/yyyy", {
            fallbackDate: "--/--/----",
            showAsTodayIfSameDay: false,
            isDoB: true,
          })}
          <br />
        </Typography>
      </Box>
      <Box component="dt" display="inline">
        <Typography appearance={labelAppearance} className={bodyClass}>
          PCP:{" "}
        </Typography>
      </Box>
      <Box component="dd" display="inline">
        <Typography appearance={labelAppearance} className={bodyClass}>
          {pcpName}
          <br />
        </Typography>
      </Box>
      <Box component="dt" display="inline">
        <Typography appearance={labelAppearance} className={bodyClass}>
          CT:{" "}
        </Typography>
      </Box>
      <Box component="dd" display="inline">
        <Typography appearance={labelAppearance} className={bodyClass}>
          {cctName}{" "}
          {!cctAssigned ? <CallSplit className={classes.callSplit} /> : null}
          <br />
        </Typography>
      </Box>
      {showEligibilityMessaging &&
        (User.isUserIneligible(member) ||
          User.isUserPendingIneliglble(member)) && (
          <EligibilityMessaging
            memberInfo={member}
            pendingIneligibleMessage={pendingIneligibleMessage}
            ineligibleMessage="Ineligible Account."
            inactiveMessage="Act no longer active."
            ctm
            typographyProps={{ variant: "body2" }}
            fontStyle="italic"
            color="text.secondary"
            pt={2}
          />
        )}
    </Box>
  );
};

export default MemberIdCard;
