// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentNoteOutput
 */
export interface AppointmentNoteOutput  {
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteOutput
     */
    authorName?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteOutput
     */
    appointmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteOutput
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteOutput
     */
    createdBy?: number;
}

export function AppointmentNoteOutputFromJSON(json: any): AppointmentNoteOutput {
    return {
        'authorName': !exists(json, 'author_name') ? undefined : json['author_name'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
    };
}

export function AppointmentNoteOutputToJSON(value?: AppointmentNoteOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'author_name': value.authorName,
        'appointment_id': value.appointmentId,
        'note': value.note,
        'created_by': value.createdBy,
    };
}


