import React from "react";
import { isBefore } from "date-fns";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Box from "components/Box";
import { selectFeatures } from "shared/features/featureFlags/selectors";

import Card from "components/Card";
import ProviderName from "./ProviderName";
import VisitTime from "./VisitTime";
import ProviderAvatar from "./ProviderAvatar";
import VisitTypeName from "./VisitTypeName";
import VisitStates from "./VisitStates";

import {
  Appointment,
  AppointmentComponentTypeEnum,
} from "shared/fetch/src/models/Appointment";
import AppointmentType from "./AppointmentType";
import ActionLinks from "./ActionLinks";
import ConversationName from "./ConversationName";
import CheckInActionButton from "./CheckInButton";
import { Link } from "react-router-dom";
import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

interface StyleProps {
  isPastVisit: boolean;
}

const useStyles = makeStyles(({ spacing, breakpoints, palette }) =>
  createStyles({
    card: {
      marginBottom: "1rem",
      padding: "1rem",
      backgroundColor: (props: StyleProps) =>
        props.isPastVisit ? "#EEEFF0" : palette.white,

      "&.MuiCard-root": {
        boxShadow: "none ",
      },

      "&:hover": {
        boxShadow: "2px 2px 6px rgb(135 160 182 / 20%)",
      },
    },
    bar: {
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    layout: {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      gridAutoRows: "minmax(0.75rem, auto)",
      gridGap: "0.25rem 0.5rem",
      gridTemplateAreas: `"avatar title status"
      "avatar details time"
      "avatar appt-type ."
      ". . ."
      ". actions button"`,

      [breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr auto",
        gridTemplateAreas: `"title title"
      "details details"
      "time time"
      "appt-type ."
      "actions button"`,
      },
    },
    avatar: {
      gridArea: "avatar",
      [breakpoints.down("sm")]: {
        display: "none",
      },
    },
    title: {
      display: "flex",
      alignItems: "center",
      gridArea: "title",
    },
    details: {
      gridArea: "details",
    },
    time: {
      gridArea: "time",
    },
    status: {
      gridArea: "status",
      justifySelf: "end",
      [breakpoints.down("sm")]: {
        display: "none",
      },
    },
    apptType: {
      gridArea: "appt-type",
    },
    button: {
      gridArea: "button",
      display: "flex",
      flexDirection: "row-reverse",
    },
    actions: {
      gridArea: "actions",
    },
    link: {
      textDecoration: "none",
      color: "inherit",
      "&:hover": {
        textDecoration: "none",
        color: "inherit",
      },
    },
  })
);

const Visit = ({ visit }: { visit: Appointment }) => {
  const isPastVisit: boolean = isBefore(visit?.endAt || new Date(), new Date());
  const isInternalVisitType: boolean = visit?.internal === true;
  const isSelfScheduleVisit: boolean =
    visit?.componentType ===
    AppointmentComponentTypeEnum.SelfScheduleAppointmentComponent;

  const classes = useStyles({ isPastVisit });

  const to = `/members/${visit?.patient?.id}/conversations/${visit?.episodeId}/component/${visit?.componentId}?${visit?.id}`;

  const features = selectFeatures();
  const hasMissedAppointmentPolicy = features.hasMissedAppointmentPolicy();
  const hasCareTimelineAccess = useAccess({
    to: PermissionsEnum.CareTimelineRead,
  });
  const hasConversationsSeparatedFromVisits = features.hasVisitSeparation();
  const showConversationName =
    (!isSelfScheduleVisit && hasConversationsSeparatedFromVisits) ||
    !hasConversationsSeparatedFromVisits;

  const isMissedOrLateCancelledVisit =
    hasMissedAppointmentPolicy &&
    (visit?.disposition === "late_cancel" ||
      visit?.disposition === "no_show" ||
      visit?.disposition === "cancel");
  const disposition = hasMissedAppointmentPolicy
    ? isMissedOrLateCancelledVisit
    : visit?.disposition === "cancel";

  return (
    <Card className={classes.card} data-testid="visit-card">
      <Box className={classes.layout} component="dl">
        <Box className="sr-only" component="dt">
          Visit Name:{" "}
        </Box>
        <Box component="dd">
          <Link
            to={to}
            className={classes.link}
            data-testid="link-to-visit-details"
          >
            <div className={classes.title}>
              <VisitTypeName visit={visit} /> <ChevronRightIcon />
            </div>
          </Link>
        </Box>
        <div className={classes.avatar}>
          <ProviderAvatar
            altName={visit?.provider?.name || ""}
            avatarUrl={visit?.provider?.avatarUrl || ""}
          />
        </div>
        <div className={classes.details}>
          <Box className="sr-only" component="dt">
            Provider Name:{" "}
          </Box>
          <Box component="dd" display="inline">
            {visit?.provider && <ProviderName provider={visit?.provider} />}
          </Box>
          {visit && hasCareTimelineAccess && (
            <>
              {visit?.provider?.name && showConversationName && (
                <span className={classes.bar} aria-hidden="true">
                  |
                </span>
              )}
              <Box className="sr-only" component="dt">
                Conversation Name:{" "}
              </Box>
              <Box component="dd" display="inline">
                {showConversationName && <ConversationName visit={visit} />}
              </Box>
            </>
          )}
        </div>
        <div className={classes.time}>
          <Box className="sr-only" component="dt">
            Visit Time:{" "}
          </Box>
          <Box component="dd">
            {visit?.startAt && visit?.endAt && (
              <VisitTime startAt={visit?.startAt} endAt={visit?.endAt} />
            )}
          </Box>
        </div>
        <div className={classes.apptType}>
          <Box className="sr-only" component="dt">
            Appointment Type:{" "}
          </Box>
          <Box component="dd">{<AppointmentType visit={visit} />}</Box>
        </div>
        <div className={classes.status}>
          <VisitStates
            visitDisposition={visit?.disposition}
            hasMissedAppointmentPolicy={hasMissedAppointmentPolicy}
            state={(visit as any)?.state}
            isPastVisit={isPastVisit}
            calendarEventInfo={{
              title: "Crossover visit",
              reason: visit?.reason,
              videoConference: visit?.videoConference,
              startDate: visit?.startAt,
              endDate: visit?.endAt,
              clinic: visit?.clinic,
              method: visit?.method,
            }}
          />
        </div>
        <div className={classes.actions}>
          {!disposition && (
            <ActionLinks
              state={(visit as any)?.state}
              visitStartAt={visit?.startAt}
              componentId={visit?.componentId}
              episodeId={visit?.episodeId}
              memberId={visit?.patient?.id}
              isInternalVisit={isInternalVisitType}
              canReschedule={visit?.canReschedule}
              canVisitBeCancelled={visit?.canVisitBeCancelled}
            />
          )}
        </div>
        <div className={classes.button}>
          {!disposition && (
            <CheckInActionButton color={"secondary"} visit={visit} hasChevron />
          )}
        </div>
      </Box>
    </Card>
  );
};

export default Visit;
