import React from "react";
import { useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography from "components/Typography";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { selectLoggedInUser } from "shared/features/user/selectors";
import ApartmentIcon from "@mui/icons-material/Apartment";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Link } from "components/Button";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { Box } from "@mui/material";
import { ReferralComponentCoreStateEnum } from "shared/fetch/src/models/ReferralComponentCore";
import { isInCheckInState } from "components/ComponentCard/utils";

interface visitIconIprops {
  visitMethod?: string;
  videoActiveLink: boolean;
}

interface videoVisitIprops {
  videoActiveLink: boolean;
  visit: Appointment;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    textArea: {
      paddingLeft: "6px",
    },
    boxArea: {
      alignItems: "center",
      display: "flex",
    },
    icon: {
      height: "16px",
      width: "16px",
    },
    videoLinkOut: {
      padding: "0 6px 0 0",
      color: palette.text.link,
    },
    passcode: {
      paddingLeft: "6px",
    },
  })
);

const VisitIcons = ({ visitMethod, videoActiveLink }: visitIconIprops) => {
  const classes = useStyles();
  const { palette } = useTheme();
  if (visitMethod === "phone") {
    return (
      <LocalPhoneIcon
        className={classes.icon}
        data-testid="phone-method"
        name="phone-icon"
      />
    );
  } else if (visitMethod === "video") {
    return (
      <VideocamIcon
        className={classes.icon}
        style={videoActiveLink ? { color: palette.text.link } : {}}
        data-testid="video-method"
        name="videocam-icon"
      />
    );
  } else {
    return (
      <ApartmentIcon
        className={classes.icon}
        data-testid="inperson-method"
        name="apartment-icon"
      />
    );
  }
};

const VideoVisit = ({ videoActiveLink, visit }: videoVisitIprops) => {
  const classes = useStyles();
  if (!videoActiveLink) {
    return <>Video visit</>;
  } else {
    return (
      <>
        <Link
          className={classes.videoLinkOut}
          target="_blank"
          onClick={() => window.open(visit?.videoConference?.url, "_blank")}
          title="video conference link"
          data-testid="video-linkout"
        >
          <Typography appearance="smallBody" fontWeightNormal>
            Video visit
          </Typography>
          <Typography
            appearance="smallBody"
            className={classes.passcode}
            color="textSecondary"
          >
            {visit?.videoConference?.passcode &&
              ` Password: ${visit?.videoConference?.passcode}`}
          </Typography>
        </Link>
      </>
    );
  }
};

const truncate = (str: string) => {
  const cutoffLength = 65 - "In person visit @".length;
  return str.length > cutoffLength
    ? str.substring(0, cutoffLength) + "..."
    : str;
};

function AppointmentType({ visit }: { visit: Appointment }) {
  const classes = useStyles();
  const user = selectLoggedInUser();
  const visitMethod = visit?.method;
  const clinicName = truncate(visit?.clinic?.name || "");
  const patientName = visit?.patient?.name;
  const patientCellPhone = visit?.patientCellPhone;
  const declined = visit?.state === ReferralComponentCoreStateEnum.Declined;
  const isScheduledState =
    visit?.state === ReferralComponentCoreStateEnum.Scheduled;
  const checkInState = isInCheckInState(visit);

  const callText = !user?.ctm
    ? "| Provider will call you"
    : `Call ${patientName} at ${patientCellPhone}`;
  const videoActiveLink: boolean =
    (checkInState || isScheduledState) &&
    !declined &&
    typeof visit?.videoConference?.url !== "undefined" &&
    visit?.videoConference?.url !== null;

  return (
    <Box className={classes.boxArea} data-testid="visit-method">
      <VisitIcons visitMethod={visitMethod} videoActiveLink={videoActiveLink} />
      <Typography
        appearance="smallBody"
        component="span"
        fontWeightNormal
        color="textSecondary"
        className={classes.textArea}
      >
        {visitMethod === "phone" && <>Phone visit {callText}</>}
        {visitMethod === "video" && (
          <VideoVisit videoActiveLink={videoActiveLink} visit={visit} />
        )}
        {visitMethod === "in_person" && <>In person visit @{clinicName} </>}
      </Typography>
    </Box>
  );
}

export default AppointmentType;
