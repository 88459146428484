import { State } from "shared/types/state";
import { jwtDecode } from "./";

export const getUserId = (state: State): number => {
  if (state.auth && state.auth.accessToken) {
    const data = jwtDecode(state.auth.accessToken);
    return data.sub;
  }
  return 0;
};

export const getAuthorizedUrl = (state: State, url?: string) => {
  const token = state.auth?.accessToken;
  const separator = url && url.includes("?") ? "&" : "?";
  return url ? `${url}${separator}access_token=${token}` : null;
};
