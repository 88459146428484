import React, { useState } from "react";
import clsx from "clsx";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Link, FormLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import PDF from "styles/images/pdf.svg";
import { UploadedDocument } from "components/MessagingForm";
import AttachFile from "components/AttachFile";
import AttachmentPreview from "components/AttachmentPreview";
import { DocumentObjectDetails } from "shared/fetch/src/models/DocumentObjectDetails";
import AttachmentViewerModal, {
  AttachmentViewerModeEnum,
} from "components/AttachmentViewerModal";
import createStyles from '@mui/styles/createStyles';

interface IAddInsuranceCardProps {
  frontCard: UploadedDocument[];
  setFrontCard: any;
  backCard: UploadedDocument[];
  setBackCard: any;
  handleFrontCardUpload: (_file: File, result: DocumentObjectDetails) => void;
  handleBackCardUpload: (_file: File, result: DocumentObjectDetails) => void;
  title: string;
  size?: "sm" | "lg";
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    basicCard: ({ size }: { size: "sm" | "lg" }) => ({
      position: "relative",
      overflowWrap: "anywhere",
      width: "50%",
      height: `${size === "sm" ? 137 : 188}px`,
      border: `1px dashed ${palette.appBackground?.darkGrey}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: palette.appBackground?.main,
      borderRadius: "6px",
      "& .attachment-header": {
        display: "none",
      },
      "& .attachment": {
        width: "100%",
        height: "100%",
      },
      "& .attachment-badge": {
        background: "none !important",
        display: "initial",
      },
      "& .attachment-badge-container": {
        padding: "0px",
        width: "100%",
        height: "100%",
      },
      "& .MuiButton-label": {
        border: `2px solid ${palette.primary.main}`,
        fontSize: "0.5rem",
        borderRadius: "32px",
        padding: "6px 16px",
      },
      "& .MuiButton-textPrimary": {
        textDecoration: "none",
        color: palette.text.primary,
      },
    }),
    frontCard: {
      "& .delete-attachment-button": {
        display: "none",
      },
    },
    frontCardUploaded: {
      width: "50%",
      border: "1px solid #DFE1E4",
      padding: "8px",
      color: "#606A76",
      "& .attachment-header": {
        display: "none",
      },
      "& .icon-close-attachment": {
        display: "none",
      },
      "& .attachment": {
        width: "46px",
        height: "29px",
      },
    },
    backCard: {
      "& .icon-close-attachment": {
        display: "none",
      },
      "& .MuiBadge-badge": {
        display: "none",
      },
    },
    insuranceCardContainer: {
      display: "flex",
      gap: "16px",
      marginTop: "8px",
      marginBottom: "16px",
    },
    isPdf: {
      display: "flex",
    },
    frontCardPdfContainer: {
      textAlign: "center",
    },
    closeIcon: {
      position: "absolute",
      top: "6px",
      right: "8px",
      cursor: "pointer",
      color: "#606A76",
    },
    jpgContainer: {
      display: "inline-block !important",
    },
    jpgCloseIcon: {
      position: "absolute",
      top: "6px",
      right: "8px",
      cursor: "pointer",
      color: "#606A76",
    },
    viewCard: {
      color: "#1C74AB",
      textDecoration: "underline",
      cursor: "pointer",
    },
    imageContainer: {
      display: "flex",
      alignItems: "center",
    },
  })
);
const AddInsuranceCards: React.FC<IAddInsuranceCardProps> = ({
  frontCard,
  setFrontCard,
  backCard,
  setBackCard,
  handleFrontCardUpload,
  handleBackCardUpload,
  title,
  size = "sm",
}) => {
  const classes = useStyles({ size });

  const isPdf = (pdfFile: string) => String(pdfFile)?.endsWith(".pdf");
  const [showDocumentModal, setShowDocumentModal] = useState<boolean>(true);
  const name = frontCard?.[0]?.name;
  const backName = backCard?.[0]?.name;
  const isPdfName: boolean = isPdf(frontCard?.[0]?.name);
  const [fileToView, setFileToView] = useState<UploadedDocument[]>([]);
  const toggleModal = () => setShowDocumentModal(!showDocumentModal);
  const viewCard = (card: any) => {
    setFileToView(card);
    toggleModal();
  };
  const closeUploadedView = () => {
    setFrontCard([]);
  };
  const closeUploadedBackView = () => {
    setBackCard([]);
  };
  return (
    <>
      {fileToView?.[0]?.url && showDocumentModal && (
        <AttachmentViewerModal
          open
          title={fileToView?.[0]?.name || "Document"}
          fileUrl={fileToView?.[0]?.url}
          toggle={toggleModal}
          viewer={AttachmentViewerModeEnum.PDF}
          hideDownload
        />
      )}
      <FormLabel>{title}</FormLabel>
      <Box className={classes.insuranceCardContainer}>
        <div
          className={clsx(
            classes.basicCard,
            classes.frontCard,
            !isPdfName && name && classes.jpgContainer
          )}
          data-e2e="upload-front-card"
        >
          {!name && (
            <>
              <AttachFile
                multiple={false}
                onAttachFile={handleFrontCardUpload}
                isInsuranceCard
                buttonText="Upload front card"
              />
            </>
          )}
          {name && !isPdfName && (
            <span data-e2e="front-card-image-uploaded">
              <AttachmentPreview documents={frontCard} />
              <CancelIcon
                className={classes.jpgCloseIcon}
                onClick={closeUploadedView}
              />
            </span>
          )}
          {isPdfName && (
            <>
              <div className={classes.frontCardPdfContainer}>
                <p className={classes.imageContainer}>
                  <img alt="PDF Logo" src={PDF} /> <span>{name}</span>
                </p>
                <Link
                  onClick={() => viewCard(frontCard)}
                  className={classes.viewCard}
                >
                  View card
                </Link>
                <CancelIcon
                  className={classes.closeIcon}
                  onClick={closeUploadedView}
                />
              </div>
            </>
          )}
        </div>

        <div
          className={clsx(
            classes.basicCard,
            classes.backCard,
            !isPdf(backCard?.[0]?.name) && backName && classes.jpgContainer
          )}
          data-e2e="upload-back-card"
        >
          {!backName && (
            <AttachFile
              multiple={false}
              onAttachFile={handleBackCardUpload}
              isInsuranceCard
              buttonText="Upload back card"
            />
          )}
          {backName && !isPdf(backCard?.[0]?.name) && (
            <span data-e2e="back-card-image-uploaded">
              <AttachmentPreview documents={backCard} />
              <CancelIcon
                className={classes.jpgCloseIcon}
                onClick={closeUploadedBackView}
              />
            </span>
          )}
          {isPdf(backCard?.[0]?.name) && (
            <>
              <div className={classes.frontCardPdfContainer}>
                <p className={classes.imageContainer}>
                  <img alt="PDF Logo" src={PDF} />
                  <span> {backName}</span>
                </p>
                <Link
                  onClick={() => viewCard(backCard)}
                  className={classes.viewCard}
                >
                  View card
                </Link>
                <CancelIcon
                  className={classes.closeIcon}
                  onClick={closeUploadedBackView}
                />
              </div>
            </>
          )}
        </div>
      </Box>
    </>
  );
};

export default AddInsuranceCards;
