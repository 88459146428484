/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DependentsResponse,
    DependentsResponseFromJSON,
    DependentsResponseToJSON,
} from '../models/DependentsResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    InviteDependentRequestBody,
    InviteDependentRequestBodyFromJSON,
    InviteDependentRequestBodyToJSON,
} from '../models/InviteDependentRequestBody'
import {
    InviteDependentResponse,
    InviteDependentResponseFromJSON,
    InviteDependentResponseToJSON,
} from '../models/InviteDependentResponse'
import {
    MemberAccountInfoOutput,
    MemberAccountInfoOutputFromJSON,
    MemberAccountInfoOutputToJSON,
} from '../models/MemberAccountInfoOutput'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateCenterAndPcpRequestBody,
    UpdateCenterAndPcpRequestBodyFromJSON,
    UpdateCenterAndPcpRequestBodyToJSON,
} from '../models/UpdateCenterAndPcpRequestBody'


export interface GetDependentsRequest {
    id: string;
    show?: GetDependentsShowEnum;
    status?: GetDependentsStatusEnum;
}
export interface InviteMemberDependentRequest {
    id: string;
    dependentId: string;
    inviteDependentRequestBody: InviteDependentRequestBody;
}
export interface ProcessMemberCCDARequest {
    id: string;
}
export interface UpdateCenterAndPcpRequest {
    id: string;
    updateCenterAndPcpRequestBody: UpdateCenterAndPcpRequestBody;
}


export interface IMembersApi {
      getDependents: (requestParameters: GetDependentsRequest) => Promise<DependentsResponse>
      inviteMemberDependent: (requestParameters: InviteMemberDependentRequest) => Promise<InviteDependentResponse>
      processMemberCCDA: (requestParameters: ProcessMemberCCDARequest) => Promise<void>
      updateCenterAndPcp: (requestParameters: UpdateCenterAndPcpRequest) => Promise<MemberAccountInfoOutput>
      getDependentsWithResponse: (requestParameters: GetDependentsRequest) => Promise<{ value: DependentsResponse, response: any}>
      inviteMemberDependentWithResponse: (requestParameters: InviteMemberDependentRequest) => Promise<{ value: InviteDependentResponse, response: any}>
      processMemberCCDAWithResponse: (requestParameters: ProcessMemberCCDARequest) => Promise<{ value: void, response: any}>
      updateCenterAndPcpWithResponse: (requestParameters: UpdateCenterAndPcpRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
}

/**
 * no description
 */
export class MembersApi extends runtime.BaseAPI {

    /**
     * Returns a list of dependents for a member
     * Returns a list of dependents for a member
     */
    getDependentsRaw = async (requestParameters: GetDependentsRequest): Promise<{ runtime: runtime.ApiResponse<DependentsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDependents.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.show !== undefined) {
            queryParameters['show'] = requestParameters.show;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/dependents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DependentsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Returns a list of dependents for a member
     * Returns a list of dependents for a member
     */
    getDependents = async (requestParameters: GetDependentsRequest): Promise<DependentsResponse> => {
        const { runtime } = await this.getDependentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Returns a list of dependents for a member
     * Returns a list of dependents for a member
     */
    getDependentsWithResponse = async (requestParameters: GetDependentsRequest): Promise<{ value: DependentsResponse, response: any}> => {
        const { runtime, response } = await this.getDependentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Invite a dependent to join the xop. This will send an email to the dependent with a link to join the xop
     */
    inviteMemberDependentRaw = async (requestParameters: InviteMemberDependentRequest): Promise<{ runtime: runtime.ApiResponse<InviteDependentResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inviteMemberDependent.');
        }

                        if (requestParameters.dependentId === null || requestParameters.dependentId === undefined) {
            throw new runtime.RequiredError('dependentId','Required parameter requestParameters.dependentId was null or undefined when calling inviteMemberDependent.');
        }

                        if (requestParameters.inviteDependentRequestBody === null || requestParameters.inviteDependentRequestBody === undefined) {
            throw new runtime.RequiredError('inviteDependentRequestBody','Required parameter requestParameters.inviteDependentRequestBody was null or undefined when calling inviteMemberDependent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/dependents/{dependent_id}/invite`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"dependent_id"}}`, encodeURIComponent(String(requestParameters.dependentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteDependentRequestBodyToJSON(requestParameters.inviteDependentRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => InviteDependentResponseFromJSON(jsonValue)), response };
    }

    /**
     * Invite a dependent to join the xop. This will send an email to the dependent with a link to join the xop
     */
    inviteMemberDependent = async (requestParameters: InviteMemberDependentRequest): Promise<InviteDependentResponse> => {
        const { runtime } = await this.inviteMemberDependentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Invite a dependent to join the xop. This will send an email to the dependent with a link to join the xop
     */
    inviteMemberDependentWithResponse = async (requestParameters: InviteMemberDependentRequest): Promise<{ value: InviteDependentResponse, response: any}> => {
        const { runtime, response } = await this.inviteMemberDependentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member ccda from elation, process it to a pdf and upload it to members documents
     */
    processMemberCCDARaw = async (requestParameters: ProcessMemberCCDARequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling processMemberCCDA.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/process_ccda`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Fetch member ccda from elation, process it to a pdf and upload it to members documents
     */
    processMemberCCDA = async (requestParameters: ProcessMemberCCDARequest): Promise<void> => {
        const { runtime } = await this.processMemberCCDARaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member ccda from elation, process it to a pdf and upload it to members documents
     */
    processMemberCCDAWithResponse = async (requestParameters: ProcessMemberCCDARequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.processMemberCCDARaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Allow members in XOP to update core pod and PCP
     */
    updateCenterAndPcpRaw = async (requestParameters: UpdateCenterAndPcpRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCenterAndPcp.');
        }

                        if (requestParameters.updateCenterAndPcpRequestBody === null || requestParameters.updateCenterAndPcpRequestBody === undefined) {
            throw new runtime.RequiredError('updateCenterAndPcpRequestBody','Required parameter requestParameters.updateCenterAndPcpRequestBody was null or undefined when calling updateCenterAndPcp.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/update_center_and_pcp`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCenterAndPcpRequestBodyToJSON(requestParameters.updateCenterAndPcpRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Allow members in XOP to update core pod and PCP
     */
    updateCenterAndPcp = async (requestParameters: UpdateCenterAndPcpRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.updateCenterAndPcpRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Allow members in XOP to update core pod and PCP
     */
    updateCenterAndPcpWithResponse = async (requestParameters: UpdateCenterAndPcpRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.updateCenterAndPcpRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetDependentsShowEnum {
    All = 'all',
    Adult = 'adult',
    Minor = 'minor',
    Nested = 'nested'
}
/**
    * @export
    * @enum {string}
    */
export enum GetDependentsStatusEnum {
    All = 'all',
    Registered = 'registered'
}

export const fetchMembersApi: IMembersApi  = new MembersApi();
