import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useRequest } from "redux-query-react";
import { useLocation } from "react-router";

import { ReactQueryDevtools } from "react-query/devtools";

import { JsonUser as User } from "shared/api/src/models/JsonUser";
import { getActionableItems } from "shared/features/actionableItems";
import { useGetCmsMenuHierarchy } from "shared/state/cms/useGetCmsMenuHierarchy";
import { useGetUpcomingAppointments } from "shared/state/appointments";
import { fetchUserFromTokenRequest } from "shared/features/user";
import { fetchActionableItemsRedirectUrlRequest } from "shared/features/actionableItems";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { usePartialUpdateUser } from "shared/features/user/usePartialUpdateUser";
import useFormatDate from "shared/utils/useFormatDate";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import { State } from "../../@types";
import AppBar from "./AppBar";
import CmsMenuHierarchy from "./CmsMenuHierarchy";
import useNavBarRoutes from "./navigationBarRoutes";
import useDraftSafeNavigate from "hooks/useDraftSafeNavigate";

import {
  filterOutInactiveContentTypes,
  activeCmsContentTypes,
} from "features/cms/utils";
import useRefetch from "../../utils/useInterval";
import { getCmsMenuHierarchyWithPath } from "features/cms/pages/utils/getCmsMenuHierarchyWithPath";
import Box from "components/Box";
import Skeleton from "components/Skeleton";
import BannerMessages from "components/BannerMessages";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import MobileNavigationTabs from "./MobileNavigationTabs";
import {
  useFeatureFlags,
  featureFlagKey,
} from "shared/features/featureFlags/useFeatureFlagsForUser";
import { useQueryClient } from "react-query";
import useMobileCheck from "hooks/useMobileCheck";
import { getTokenSync } from "shared/features/auth/utils";
import { MEMBER_ACCESS_ID } from "shared/features/auth";
import useGetPatientAccess from "shared/features/patientAccess/useGetPatientAccess";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import useDeletePatientAccessToken from "shared/features/patientAccess/useDeletePatientAccessToken";
import isElectron from "utils/isElectron";
import { ActionableItemDetails } from "shared/fetch/src/models/ActionableItemDetails";
import { AccessPlan } from "shared/fetch/src/models/AccessPlan";
import { CmsNavigationSchemaHierarchy } from "shared/fetch/src/models/CmsNavigationSchemaHierarchy";

interface Props {
  user?: User;
  actionableItems?: ActionableItemDetails[];
  accessToken?: string;
  forceRequest?: () => void;
}

interface IUserMetaData {
  userClass: string;
  accountId: string | null | undefined;
  efId: number | null | undefined;
  affinity: string | null | undefined;
}

const usePrevious = (value: any) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const getAccessType = () => {
  const userAgent = window.navigator.userAgent;
  if (userAgent === null) {
    return null;
  } else if (userAgent.includes("XOApp-iOS")) {
    return "Native iOS";
  } else if (userAgent.includes("XOApp-Android")) {
    return "Native Android";
  } else if (
    userAgent.includes("Android") ||
    userAgent.includes("iPhone") ||
    userAgent.includes("webOS") ||
    userAgent.includes("iPad") ||
    userAgent.includes("BlackBerry") ||
    userAgent.includes("IEMobile") ||
    userAgent.includes("Opera Min")
  ) {
    return "Mobile Web";
  } else {
    return "Desktop Web";
  }
};

const getAccessPlanMapping = (accessPlans: AccessPlan[] = []) => {
  const accessPlanMapping: any = {
    hasLegacyAccessPlanType: false,
    hasOnsiteAccessPlanType: false,
    hasNearsiteAccessPlanType: false,
    hasVirtualAccessPlanType: false,
    hasTravelAccessPlanType: false,
    legacyAccessPlanName: "",
    onsiteAccessPlanName: "",
    nearsiteAccessPlanName: "",
    virtualAccessPlanName: "",
    travelAccessPlanName: "",
  };

  accessPlans.forEach((plan: AccessPlan) => {
    switch (plan?.planType) {
      case "legacy":
        accessPlanMapping.hasLegacyAccessPlanType = true;
        accessPlanMapping.legacyAccessPlanName = plan?.name;
        break;
      case "onsite":
        accessPlanMapping.hasOnsiteAccessPlanType = true;
        accessPlanMapping.onsiteAccessPlanName = plan?.name;
        break;
      case "nearsite":
        accessPlanMapping.hasNearsiteAccessPlanType = true;
        accessPlanMapping.nearsiteAccessPlanName = plan?.name;
        break;
      case "virtual":
        accessPlanMapping.hasVirtualAccessPlanType = true;
        accessPlanMapping.virtualAccessPlanName = plan?.name;
        break;
      case "travel":
        accessPlanMapping.hasTravelAccessPlanType = true;
        accessPlanMapping.travelAccessPlanName = plan?.name;
        break;
      default:
        break;
    }
  });

  return accessPlanMapping;
};

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    layout: {
      display: "grid",
      // when using cypress with electron (which we use in CI) dvh-sized divs will not scroll,
      // but we need dvh's in production for correct mobile display in Chrome, so ...
      height: `100${isElectron() ? "vh" : "dvh"}`,
      gridTemplateColumns: "1fr",
      gridTemplateRows: "auto auto auto 1fr auto",
      gridTemplateAreas: `"appBar"
      "bannerMessages"
      "cmsMenuHierarchy"
      "children"
      "."`,
      [breakpoints.down("sm")]: {
        gridTemplateAreas: `"appBar"
      "bannerMessages"
      "cmsMenuHierarchy"
      "children"
      "mobileNavigationTabs"`,
      },
    },
    appBar: {
      gridArea: "appBar",
    },
    bannerMessages: {
      gridArea: "bannerMessages",
    },
    cmsMenuHierarchy: {
      gridArea: "cmsMenuHierarchy",
    },
    children: {
      gridArea: "children",
      overflowY: "auto",
      [breakpoints.down("sm")]: {
        paddingBottom: (props: { hideMobileNavigation: boolean }) =>
          props.hideMobileNavigation ? 0 : 72,
      },
    },
    mobileNavigationTabs: {
      gridArea: "mobileNavigationTabs",
      display: (props: { hideMobileNavigation: boolean }) =>
        props.hideMobileNavigation ? "none" : "contents",
    },
  })
);

export const Layout: React.FunctionComponent<Props> = (props): JSX.Element => {
  // Map state to props
  const {
    entities: { user = {} as User },
    auth: { accessToken = "" },
    actionableItems = [],
    nativeApp: { isApp = false, osName, osVersion, appVersion },
  } = useSelector((state: State) => ({
    ...state,
    actionableItems: getActionableItems(state),
  }));

  // Constant Variables
  const location = useLocation();
  const dispatch = useDispatch();
  const features = selectFeatures();
  const isMobile = useMobileCheck();

  const previousAccessToken = usePrevious(accessToken);
  const isEngagementScreen = React.useMemo(
    () => location.pathname === "/engagement-activation",
    [location.pathname]
  );
  const path = location.pathname.split("/");
  const isAppConversationPage =
    path[1] === "members" &&
    path[3] === "conversations" &&
    path.length === 5 &&
    features.hasMemberInlineMessaging() &&
    isMobile &&
    !user.ctm;

  const hideMobileNavigation =
    !isMobile || isEngagementScreen || isAppConversationPage;
  const classes = useStyles({
    hideMobileNavigation,
  });
  let pendoTimeout: any;

  const { partiallyUpdateUser } = usePartialUpdateUser();
  const formatDateType = useFormatDate();

  const userNav = useNavBarRoutes(user);
  const safeNavigate = useDraftSafeNavigate();
  const { mutateAsync: refetchMemberAccessUser } = useGetPatientAccess();
  const memberAccessId = getTokenSync(MEMBER_ACCESS_ID);

  // Network Requests
  const [
    { isPending: isUserRequestPending, queryCount: userRequestQueryCount },
    refetchUser,
  ] = useRequest(fetchUserFromTokenRequest());
  const [, callback] = useRequest(fetchActionableItemsRedirectUrlRequest());

  const { isLoading, data } = useFeatureFlags();
  const queryClient = useQueryClient();

  const {
    isPending: isCmsHierarchyItemsRequestPending,
    queryCount: cmsHierarchyItemsRequestQueryCount,
    cmsMenuHierarchyItems,
  } = useGetCmsMenuHierarchy();

  const { refreshUpcomingAppointments, isPending: isRefreshingAppointments } =
    useGetUpcomingAppointments({
      // @ts-ignore: Object is possibly 'null'.
      id: user.id?.toString(),
    });

  const { accountInfo } =
    useGetAccountInfo({
      // @ts-ignore: Object is possibly 'null'.
      id: user?.id?.toString(),
    }) || {};

  const { deletePatientAccessToken } = useDeletePatientAccessToken();

  // ShortPolling Hook
  useRefetch(() => {
    callback();
    refreshUpcomingAppointments();
  }, 60000);

  React.useEffect(() => {
    const hasEngagementScreen =
      !user?.ctm &&
      user?.isFirstTimeLogin &&
      user?.affinity !== "remote" &&
      accountInfo?.membershipType !== "consumer" &&
      features.hasEngagementActivation();
    if (hasEngagementScreen) {
      const state = { isFirstLoginRedirect: true };
      safeNavigate("/engagement-activation", { state });
    }
  }, [user?.xoid, accountInfo?.membershipType, user?.affinity]);

  React.useEffect(() => {
    const isCypress = window.Cypress;
    // Pendo product analytics initialization
    // don't run Pendo in cypress for now, introduces too much UI uncertainty
    // may need to revisit this decision as it introduces UI the we can't axe scan
    // the one pendo guide tested by dev introduced multiple a11y violations
    if (!isCypress && user?.xoid) {
      const userMetaData: IUserMetaData = user.ctm
        ? {
            userClass: "ctm",
            accountId: null,
            efId: null,
            affinity: null,
          }
        : {
            userClass: "member",
            accountId: `${process.env.REACT_APP_PENDO_ENV}-${user.employer}`,
            efId: user.efId,
            affinity: user.affinity,
          };

      const {
        hasLegacyAccessPlanType,
        hasOnsiteAccessPlanType,
        hasNearsiteAccessPlanType,
        hasVirtualAccessPlanType,
        hasTravelAccessPlanType,
        legacyAccessPlanName,
        onsiteAccessPlanName,
        nearsiteAccessPlanName,
        virtualAccessPlanName,
        travelAccessPlanName,
      } = getAccessPlanMapping(user.accessPlans);

      // @ts-ignore
      pendo?.initialize({
        visitor: {
          id: `${process.env.REACT_APP_PENDO_ENV}-${user.xoid}`,
          email: user.email,
          full_name: user.fullName,
          role: null,
          class: userMetaData.userClass,
          primary_clinic: user.clinicId,
          tz: accountInfo?.timezone,
          member_state: accountInfo?.state,
          member_pref_lang: user.preferredLanguage,
          member_bene_status: user.status,
          member_bene_services: user.serviceLines,
          successful_logins: user.successfulLogins,
          ef_id: userMetaData.efId,
          affinity: userMetaData.affinity,
          aetna_member: user.aetnaMember,
          sponsor: user.planSponsorControlName,
          lastismobile: isApp,
          lastmobileoperatingsystem: osName,
          lastmobileoperatingsystemversion: osVersion,
          lastmobileapplicationversion: appVersion,
          access_type: getAccessType(),
          primary: user.primary,
          relationship_code: user.relationshipCode,
          hasLegacyAccessPlanType,
          hasOnsiteAccessPlanType,
          hasNearsiteAccessPlanType,
          hasVirtualAccessPlanType,
          hasTravelAccessPlanType,
          legacyAccessPlanName,
          onsiteAccessPlanName,
          nearsiteAccessPlanName,
          virtualAccessPlanName,
          travelAccessPlanName,
          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: userMetaData.accountId,
          ef_id: userMetaData.efId,
          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
    }
  }, [user?.xoid, accountInfo?.id]);

  React.useEffect(() => {
    if (user?.isFirstTimeLogin) {
      pendoTimeout = setTimeout(() => {
        const timeStamp = formatDateType(new Date(), "MMM d, yyyy, h:mm:ss a", {
          fallbackDate: "",
        });
        // @ts-ignore
        pendo?.track("First Time Login", {
          id: `${process.env.REACT_APP_PENDO_ENV}-${user.xoid}`,
          currentDate: timeStamp,
          isMobileApp: isApp,
        });
        partiallyUpdateUser();
      }, 60000);
    }
    return () => clearTimeout(pendoTimeout);
  }, [user?.xoid]);

  React.useEffect(() => {
    if (
      user?.xoid &&
      accountInfo?.id &&
      //@ts-ignore
      !!(window as any).utag
    ) {
      // @ts-ignore
      window.utag?.view({
        page_name: "Crossover Health",
        page_type: "Platform",
        customer_type: "",
        customer_state: accountInfo?.state,
        customer_efid: user.efId,
        customer_class: user.ctm ? "ctm" : "member",
        customer_postal_code: "",
        customer_last_name: user.lastName,
        customer_first_name: user.firstName,
        customer_email: user.email,
        customer_id: user.xoid,
        customer_country: "",
        customer_phone_number: user.cellPhone || user.homePhone || "",
        user_logged_in: "1",
      });
    }
  }, [user?.xoid, accountInfo?.id]);

  React.useEffect(() => {
    if (memberAccessId) {
      refetchMemberAccessUser({
        memberId: memberAccessId,
        note: "Refetching member access user",
      });
    }
    window.onbeforeunload = function () {
      if (isDependantAccess()) {
        deletePatientAccessToken();
      }
      return undefined;
    };

    window.onload = function () {
      const userAgent = window.navigator.userAgent;
      if (userAgent.includes("iPhone") || userAgent.includes("XOApp-iOS")) {
        document.head.insertAdjacentHTML(
          "afterbegin",
          '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />'
        );
      } else {
        document.head.insertAdjacentHTML(
          "afterbegin",
          '<meta name="viewport" content="width=device-width, initial-scale=1" />'
        );
      }
    };

    return () => {
      window.onbeforeunload = null;
      window.onload = null;
    };
  }, []);

  React.useEffect(() => {
    if (previousAccessToken && previousAccessToken !== accessToken) {
      // refetching feature flags because when the token expires and the browser is refreshed
      // the call to fetch feature flags fail and this causes the archive tab in the finalize workspace area
      // to not be displayed until the browser is refreshed again
      refetchUser().then(() => {
        queryClient.invalidateQueries(featureFlagKey);
      });
    }
  }, [previousAccessToken, accessToken]);

  const { cmsMenuHierarchyWithPath } = getCmsMenuHierarchyWithPath(
    cmsMenuHierarchyItems as CmsNavigationSchemaHierarchy[]
  );

  // show skeleton if we have not completed our initial fetches or we don't have initial data yet.
  // check if our queries are pending and also the count-- a count of > 1 indicating that we have
  // already fetched the initial set of data for the given request.
  const isPending =
    (isCmsHierarchyItemsRequestPending &&
      !cmsHierarchyItemsRequestQueryCount) ||
    (isLoading && !data) ||
    (isUserRequestPending && !userRequestQueryCount);

  if (isPending) {
    return <Skeleton appearance="page" />;
  }

  return (
    <Box
      data-e2e="app-div"
      id="layout-wrapper-div"
      tabIndex={-1}
      // when using cypress dvh-sized divs don't scroll,
      // but we need dvh's in production for correct mobile display
      // unsure why this div is set to 101 instead of 100, but I'm
      // going to preserve the 101 since it seems to be working and I suspect
      // the 101 was an intentional hack
      height={`101${isElectron() ? "vh" : "dvh"}`}
      component="div"
      display="flex"
      flexDirection="column"
      style={{
        overflow: "hidden",
      }}
    >
      <div className={classes.layout}>
        <div className={classes.appBar}>
          <AppBar
            user={user}
            dispatch={dispatch}
            hasXOLogoOnly={location.pathname
              .split("/")
              .includes("engagement-activation")}
            actionableItems={actionableItems}
            userNav={userNav}
            cmsMenuHierarchyItems={filterOutInactiveContentTypes(
              cmsMenuHierarchyWithPath,
              activeCmsContentTypes
            )}
          />
        </div>
        <div className={classes.bannerMessages}>
          <BannerMessages
            isRefreshingAppointments={isRefreshingAppointments}
            refreshUpcomingAppointments={refreshUpcomingAppointments}
          />
        </div>
        {location.pathname.split("/").includes("tools") && (
          <div className={classes.cmsMenuHierarchy}>
            <CmsMenuHierarchy
              user={user}
              cmsMenuHierarchyItems={filterOutInactiveContentTypes(
                cmsMenuHierarchyWithPath,
                activeCmsContentTypes
              )}
            />
          </div>
        )}
        <div className={classes.children}>
          <>
            {/* content below nav bar(s)*/}
            {/* direct children are in containers/Routing */}
            {props.children}
          </>
        </div>
        {!hideMobileNavigation && (
          <div className={classes.mobileNavigationTabs}>
            <MobileNavigationTabs />
          </div>
        )}
        <ReactQueryDevtools toggleButtonProps={{ style: { bottom: "64px" } }} />
      </div>
    </Box>
  );
};

export default Layout;
