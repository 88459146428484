import { Dispatch } from "redux";
import queryString from "query-string";
import unfetch from "unfetch";
import {
  ACCESS_TOKEN_KEY,
  CODE_VERIFIER_KEY,
  REFRESH_TOKEN_KEY,
  setTokens,
  removeTokens,
  MEMBER_ACCESS_TOKEN_KEY,
  MEMBER_ACCESS_ID,
} from "./";
import redirectToAuth from "./redirectToAuth";
import { JsonUser } from "shared/api/src/models/JsonUser";
import { LocalStorageCache, LogoutOptions } from "@auth0/auth0-react";
import XoConfig from "../../../models/XoConfig";
import NativeApp from "../../../models/NativeApp";

type FetchType = any;

export interface ConnectedReduxProps {
  dispatch?: Dispatch;
  fetchImpl?: FetchType;
}

interface CheckAuthenticationSettings {
  isLoggedIn?: boolean;
  refreshToken?: string;
  dispatch?: any;
  fetchImpl?: FetchType;
  isLoading?: boolean;
  user?: JsonUser;
  location?: any;
  getAccessTokenSilently?: any;
  logoutAuth0?: (options?: LogoutOptions | undefined) => Promise<void>;
}

export interface AuthState {
  error?: string;
}

export interface IConfiguration {
  authorizationEndpointUrl: string | undefined;
  clientId: string | undefined;
  window: any;
  webRoot: string;
}

const LAST_REFRESHED_KEY = "lastRefreshed";
const REFRESH_INTERVAL_MS = 60000 * 5; // Set to 5 minutes

export const getAuthorizationEndpointUrl = () => {
  const endpoint = process.env.REACT_APP_AUTH_ENDPOINT;
  if (endpoint && window?.location?.hostname) {
    return endpoint.replace(/localhost/, window.location.hostname);
  }
  return endpoint;
};

let refreshIntervalId: number = 0;

let configuration: IConfiguration = {
  authorizationEndpointUrl: getAuthorizationEndpointUrl(),
  clientId: process.env.REACT_APP_CLIENT_ID,
  window,
  webRoot: "",
};

export const setConfiguration = (config: IConfiguration) => {
  configuration = config;
};

export const getRedirectUri = (
  nextUrl: string | null = null,
  isBiometricLogin: boolean = false
): string => {
  const isProvider = configuration.window.location.origin?.includes(
    "care.team.crossoverhealth.com"
  );
  const isBiometricLogout =
    XoConfig.getValue("auth0")?.enabled &&
    getDidAuthenticateWithOauth() &&
    window.ReactNativeWebView?.isApp;
  const isAuth0LoginOrLogout =
    XoConfig.getValue("auth0")?.enabled && !getDidAuthenticateWithOauth();
  const redirectPath =
    (!isProvider && isAuth0LoginOrLogout && !isBiometricLogin) ||
    isBiometricLogout
      ? "/auth0-redirect"
      : "/oauth-redirect";
  return `${
    configuration.window.location.origin
  }${redirectPath}?next_url=${encodeURIComponent(
    nextUrl || configuration.window.location.href
  )}`;
};

export const storeCodeVerifier = (text: string) => {
  return storeToken(CODE_VERIFIER_KEY, text);
};

export const getCodeVerifier = () => {
  return getToken(CODE_VERIFIER_KEY);
};

export const storeToken = (key: string, text: string) => {
  return new Promise((resolve) => {
    configuration.window.localStorage.setItem(key, text);
    resolve(null);
  });
};

export const removeToken = (key: string) => {
  return new Promise((resolve) => {
    configuration.window.localStorage.removeItem(key);
    resolve(null);
  });
};

export const getTokenSync = (key: string) => {
  return configuration.window?.localStorage?.getItem(key);
};

const getToken = (key: string): Promise<any> => {
  return new Promise((resolve) => {
    const value = configuration.window.localStorage.getItem(key);
    resolve(value);
  });
};

const clearTokens = (dispatch: Dispatch) => {
  removeToken(ACCESS_TOKEN_KEY);
  removeToken(REFRESH_TOKEN_KEY);
  removeToken(MEMBER_ACCESS_TOKEN_KEY);

  dispatch(
    setTokens({
      accessToken: null,
      refreshToken: null,
    })
  );
};

const getAuth0AccessToken = () => {
  const localStorageCache = new LocalStorageCache();
  const auth0Key = localStorageCache
    .allKeys()
    ?.find((key) => key.includes("auth0spa") && key.includes("openid"));
  const auth0Info: any = localStorageCache?.get(auth0Key as string);
  return auth0Info?.body?.access_token;
};

const getOauthAccessToken = () => {
  return getTokenSync(ACCESS_TOKEN_KEY);
};

export const getDidAuthenticateWithOauth = () => {
  const auth0AccessToken = getAuth0AccessToken();
  const oauthAccessToken = getOauthAccessToken();

  return Boolean(oauthAccessToken && !auth0AccessToken);
};

export const getAccessToken = () => getToken(ACCESS_TOKEN_KEY);
export const getRefreshToken = () => getToken(REFRESH_TOKEN_KEY);
let loggedInUser: JsonUser | undefined;

/**
 * redirects to authentication route
 *
 * @returns {Promise<unknown>} a promise that's used only in tests,
 * TODO probably refactor tests that expects a promise and remove the return statement.
 */

export const authRedirect = (
  redirectUri: string,
  codeChallenge: any = null,
  authEndpointUrl: string | undefined = configuration.authorizationEndpointUrl,
  additionalQueryParams: Record<string, string> | null = {}
) => {
  const query = queryString.stringify({
    client_id: configuration.clientId,
    response_type: "code",
    redirect_uri: redirectUri,
    scope: "foobar", // TODO: What scope do we need?
    code_challenge: codeChallenge,
    code_challenge_method: "S256",
    ...additionalQueryParams,
  });
  const newUrl = authEndpointUrl + "?" + query;

  // If "identity_provider" query param is present, allow the assignment of an auth URL
  // for CTM login via Okta
  if (
    !XoConfig.getValue("auth0")?.enabled ||
    additionalQueryParams?.identity_provider
  ) {
    configuration.window.location.assign(newUrl);
  }
  return newUrl;
};

export const changePassword = (
  email: string,
  action: string = "update",
  redirectPath: string | null = null
) => {
  const changePasswordUrl = `${configuration.authorizationEndpointUrl}/${action}/${email}`;
  return redirectToAuth(getRedirectUri(redirectPath), changePasswordUrl);
};

export const pendoTracking = (event: string, type: string, user: JsonUser) => {
  // @ts-ignore
  pendo?.track(event, {
    id: `${process.env.REACT_APP_PENDO_ENV}-${user?.xoid}`,
    type,
  });
  return;
};

export const logout = (
  dispatch: any,
  user: JsonUser,
  type: string,
  logoutAuth0?: any
) => {
  const didAuthenticateWithOauth = getDidAuthenticateWithOauth();
  const isAuth0Logout =
    XoConfig.getValue("auth0")?.enabled && !didAuthenticateWithOauth;
  const isBiometricLogout =
    didAuthenticateWithOauth && window.ReactNativeWebView?.isApp;

  pendoTracking("logout", type, user);
  dispatch(removeTokens());
  removeToken(ACCESS_TOKEN_KEY);
  removeToken(REFRESH_TOKEN_KEY);
  removeToken(MEMBER_ACCESS_TOKEN_KEY);
  removeToken(MEMBER_ACCESS_ID);

  if (isAuth0Logout) {
    if (window.ReactNativeWebView?.isApp) {
      NativeApp.showNativeButtons();
    }
    return logoutAuth0();
  }
  if (isBiometricLogout) {
    NativeApp.biometricLogout();
    NativeApp.showNativeButtons();
    if (XoConfig.getValue("auth0")?.enabled) {
      return logoutAuth0();
    } else {
      return redirectToAuth(getRedirectUri("/"), undefined, {
        skipBiometricLogin: "true",
      });
    }
  }

  return redirectToAuth(getRedirectUri("/"), undefined, {
    skipBiometricLogin: "true",
  });
};

export const fetchAuth0Token = (
  dispatch: Dispatch,
  handleRedirect: any,
  logoutAuth0: any
) => {
  return handleRedirect()
    .then((response: any) => {
      const localStorageCache = new LocalStorageCache();
      const auth0Key = localStorageCache
        .allKeys()
        .find((key) => key.includes("auth0spa") && key.includes("openid"));
      const auth0Info: any = localStorageCache.get(auth0Key as string);
      storeToken(ACCESS_TOKEN_KEY, auth0Info?.body?.access_token);
      storeToken(REFRESH_TOKEN_KEY, auth0Info?.body?.refresh_token);
      storeToken(LAST_REFRESHED_KEY, Date.now().toString());
      removeToken(CODE_VERIFIER_KEY);

      dispatch(
        setTokens({
          accessToken: auth0Info?.body?.access_token,
          refreshToken: auth0Info?.body?.refresh_token,
        })
      );
      return response;
    })
    .catch(() => {
      if (loggedInUser) {
        pendoTracking("logout", "error", loggedInUser);
      }
      return logoutAuth0();
    });
};

/**
 * Helper function to fetch an auth token.
 * @param data
 * @param dispatch
 */
export const fetchToken = (
  data: any,
  dispatch: Dispatch,
  fetchImpl: FetchType = unfetch,
  location?: any
) => {
  return fetchImpl(configuration.webRoot + "/oauth2/token", {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
    body: queryString.stringify(data),
  })
    .then((response: any) => {
      if (response.status !== 200) {
        throw new Error("Bad request");
      }
      return response.json();
    })
    .then((response: any) => {
      storeToken(ACCESS_TOKEN_KEY, response.access_token);
      storeToken(REFRESH_TOKEN_KEY, response.refresh_token);
      storeToken(LAST_REFRESHED_KEY, Date.now().toString());
      removeToken(CODE_VERIFIER_KEY);

      dispatch(
        setTokens({
          accessToken: response.access_token,
          refreshToken: response.refresh_token,
        })
      );
      return response;
    })
    .catch(() => {
      if (loggedInUser) {
        pendoTracking("logout", "error", loggedInUser);
      }

      let queryParams;
      if (location) {
        queryParams = queryString.parse(location.search);
      }

      // If the "identity_provider" query param is present, do not throw an error and
      // allow the redirect to auth for CTM login via Okta
      if (
        XoConfig.getValue("auth0")?.enabled &&
        !queryParams?.identity_provider
      ) {
        throw new Error("Fetch token error");
      }

      if (location) {
        if (queryParams?.identity_provider) {
          redirectToAuth(
            getRedirectUri(),
            configuration.authorizationEndpointUrl,
            { identity_provider: queryParams?.identity_provider } as any
          );
        } else {
          redirectToAuth(getRedirectUri());
        }
      } else {
        redirectToAuth(getRedirectUri());
      }
    });
};

export const fetchAuthToken = (
  code: any,
  dispatch: any,
  fetchImpl: any = unfetch,
  location?: any,
  codeChallenge?: any
) => {
  return getCodeVerifier().then((storedCodeVerifier) => {
    const data = {
      grant_type: "authorization_code",
      client_id: configuration.clientId,
      code_verifier: codeChallenge || storedCodeVerifier,
      code,
      redirect_uri: getRedirectUri(),
    };

    return fetchToken(data, dispatch, fetchImpl, location);
  });
};

export const getAdditionalQueryParams = (originalQueryString: string) => {
  const whitelistedParams = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "identity_provider",
  ];
  const originalParams = queryString.parse(originalQueryString);
  const additionalParams = {};
  Object.keys(originalParams)?.map((param) => {
    if (whitelistedParams.includes(param)) {
      additionalParams[param] = originalParams?.[param];
    }
  });
  return additionalParams;
};

/**
 * Checks if authentication flow needs to be initiated.
 */
export const checkAuthentication = ({
  isLoggedIn,
  dispatch,
  isLoading,
  user,
  location,
  getAccessTokenSilently,
  logoutAuth0,
}: CheckAuthenticationSettings = {}) => {
  if (isLoading) {
    return Promise.resolve();
  }
  loggedInUser = user;
  if (isLoggedIn) {
    // Check if token refresh needed on first load and set interval for refreshes
    if (refreshIntervalId === 0) {
      const doRefresh = () => {
        if (
          XoConfig.getValue("auth0")?.enabled &&
          !getDidAuthenticateWithOauth()
        ) {
          const handleRedirect = () => {
            return getAccessTokenSilently();
          };
          fetchAuth0Token(dispatch, handleRedirect, logoutAuth0).catch(() => {
            clearTokens(dispatch);

            // Initiate login flow
            return checkAuthentication({
              isLoggedIn: false,
              dispatch,
              user: loggedInUser,
              location,
            });
          });
        } else {
          getToken(REFRESH_TOKEN_KEY).then((refreshToken: any) => {
            refresh({
              dispatch,
              refreshToken,
              location,
            });
          });
        }
      };

      const isRefreshNeeded = () => {
        return getToken(LAST_REFRESHED_KEY).then((lastRefreshed: any) => {
          if (lastRefreshed) {
            const lastRefreshedTime = parseInt(lastRefreshed, 10);
            if (!isNaN(lastRefreshedTime)) {
              const delta = Date.now() - lastRefreshedTime;
              if (delta > REFRESH_INTERVAL_MS) {
                return Promise.resolve(true);
              }
            }
          }
          return Promise.resolve(false);
        });
      };

      isRefreshNeeded().then((isNeeded: any) => {
        if (isNeeded) {
          doRefresh();
        }
      });

      // Refresh the token every 5 minutes
      refreshIntervalId = configuration.window.setInterval(() => {
        doRefresh();
      }, REFRESH_INTERVAL_MS);
    }

    return Promise.resolve();
  }

  // Only tracks if there's a user and it's a logout.
  if (user) {
    pendoTracking("logout", "error", user);
  }

  let additionalQueryParams = {};
  if (location) {
    additionalQueryParams = getAdditionalQueryParams(location.search);
  }

  // If "identity_provider" query param is present, don't reload the page.
  // Instead, allow the redirect to auth for CTM login via Okta

  if (
    XoConfig.getValue("auth0")?.enabled &&
    getDidAuthenticateWithOauth() &&
    //@ts-ignore
    !additionalQueryParams?.identity_provider
  ) {
    window.location.reload();
    return Promise.resolve();
  }

  if (location) {
    if (Object.keys(additionalQueryParams)?.length > 0) {
      return redirectToAuth(
        getRedirectUri(),
        configuration.authorizationEndpointUrl,
        additionalQueryParams as any
      );
    }
  }

  return redirectToAuth(getRedirectUri());
};

/**
 * Refreshes the auth access token.
 */
export const refresh = ({
  refreshToken,
  dispatch,
  fetchImpl = unfetch,
  location,
}: CheckAuthenticationSettings) => {
  const data = {
    grant_type: "refresh_token",
    client_id: configuration.clientId,
    refresh_token: refreshToken,
    redirect_uri: getRedirectUri(),
  };

  return fetchToken(data, dispatch, fetchImpl, location).catch(() => {
    clearTokens(dispatch);

    if (XoConfig.getValue("auth0")?.enabled) {
      window.location.reload();
      return Promise.resolve();
    }
    // Initiate login flow
    return checkAuthentication({
      isLoggedIn: false,
      refreshToken,
      dispatch,
      user: loggedInUser,
      location,
    });
  });
};
