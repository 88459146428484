/**
 * ElementList component
 */
import React, { FunctionComponent } from "react";

interface IProps {
  elements: any[];
  component?: React.ElementType;
  restProps?: any;
}

const ElementList: FunctionComponent<IProps> = (props) => {
  const { component: Element, elements, ...restProps } = props;
  return (
    <>
      {(elements || []).map((element, index) => (
        <React.Fragment key={element.id}>
          {/* @ts-ignore: Object is possibly 'null'. */}
          <Element {...restProps} {...element} index={index} />
        </React.Fragment>
      ))}
    </>
  );
};

export default ElementList;
