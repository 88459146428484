import React, { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { fetchAuthToken } from "shared/features/auth/utils";
import LinearProgress from "components/Progress/LinearProgress";
import Container from "components/Container";
import { useLocation } from "react-router";
import NativeApp from "models/NativeApp";

const Display: FC<{ error: string }> = ({ error }) => {
  return (
    <div>
      <LinearProgress />
      <Container maxWidth="lg" style={{ padding: "16px" }}>
        {error && <p data-e2e="error-message">{error}</p>}
      </Container>
    </div>
  );
};
/**
 * OAuth redirect route.
 */
const OAuthRedirect = ({ fetchImpl }: any) => {
  const [error, setError] = useState<string>("");
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const parsedQueryString = queryString.parse(location.search);

    if (parsedQueryString.code && parsedQueryString.next_url) {
      fetchAuthToken(
        parsedQueryString.code,
        dispatch,
        fetchImpl,
        location,
        parsedQueryString?.code_challenge
      )
        .then(() => {
          NativeApp.hideNativeButtons();
          window.location.assign(parsedQueryString.next_url as string);
        })
        .catch((err) => {
          setError(err.message);
        });
    }
  }, []);

  return <Display error={error} />;
};

export default OAuthRedirect;
