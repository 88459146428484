// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';


/**
 * 
 * @export
 * @interface ConversationSummaryCore
 */
export interface ConversationSummaryCore  {
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    state: ConversationSummaryCoreStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    category: ConversationSummaryCoreCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    serviceLine: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    serviceSubtype: string;
    /**
     * 
     * @type {MemberInfo}
     * @memberof ConversationSummaryCore
     */
    memberInfo: MemberInfo;
    /**
     * 
     * @type {Date}
     * @memberof ConversationSummaryCore
     */
    addedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ConversationSummaryCore
     */
    archivedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    resourceUrl: string;
    /**
     * 
     * @type {number}
     * @memberof ConversationSummaryCore
     */
    xopPodId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationSummaryCore
     */
    cctAssigned?: boolean;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ConversationSummaryCore
     */
    lastCtmUpdater?: CTMInfo;
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof ConversationSummaryCore
     */
    actionableItems?: Array<ActionableItemSummary>;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    type?: ConversationSummaryCoreTypeEnum;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ConversationSummaryCore
     */
    owner?: CTMInfo;
    /**
     * 
     * @type {Date}
     * @memberof ConversationSummaryCore
     */
    updatedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ConversationSummaryCore
     */
    billable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    subject?: ConversationSummaryCoreSubjectEnum;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    subjectFreetext?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    lastUpdatedUserType?: ConversationSummaryCoreLastUpdatedUserTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummaryCore
     */
    initialRecipientType?: ConversationSummaryCoreInitialRecipientTypeEnum;
}

export function ConversationSummaryCoreFromJSON(json: any): ConversationSummaryCore {
    return {
        'id': json['id'],
        'state': json['state'],
        'category': json['category'],
        'serviceLine': json['service_line'],
        'serviceSubtype': json['service_subtype'],
        'memberInfo': MemberInfoFromJSON(json['member_info']),
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'archivedAt': !exists(json, 'archived_at') ? undefined : new Date(json['archived_at']),
        'resourceUrl': json['resource_url'],
        'xopPodId': !exists(json, 'xop_pod_id') ? undefined : json['xop_pod_id'],
        'cctAssigned': !exists(json, 'cct_assigned') ? undefined : json['cct_assigned'],
        'lastCtmUpdater': !exists(json, 'last_ctm_updater') ? undefined : CTMInfoFromJSON(json['last_ctm_updater']),
        'actionableItems': !exists(json, 'actionable_items') ? undefined : (json['actionable_items'] as Array<any>).map(ActionableItemSummaryFromJSON),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'owner': !exists(json, 'owner') ? undefined : CTMInfoFromJSON(json['owner']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'billable': !exists(json, 'billable') ? undefined : json['billable'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'subjectFreetext': !exists(json, 'subject_freetext') ? undefined : json['subject_freetext'],
        'lastUpdatedUserType': !exists(json, 'last_updated_user_type') ? undefined : json['last_updated_user_type'],
        'initialRecipientType': !exists(json, 'initial_recipient_type') ? undefined : json['initial_recipient_type'],
    };
}

export function ConversationSummaryCoreToJSON(value?: ConversationSummaryCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'state': value.state,
        'category': value.category,
        'service_line': value.serviceLine,
        'service_subtype': value.serviceSubtype,
        'member_info': MemberInfoToJSON(value.memberInfo),
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'archived_at': value.archivedAt === undefined ? undefined : value.archivedAt.toISOString(),
        'resource_url': value.resourceUrl,
        'xop_pod_id': value.xopPodId,
        'cct_assigned': value.cctAssigned,
        'last_ctm_updater': CTMInfoToJSON(value.lastCtmUpdater),
        'actionable_items': value.actionableItems === undefined ? undefined : (value.actionableItems as Array<any>).map(ActionableItemSummaryToJSON),
        'type': value.type,
        'owner': CTMInfoToJSON(value.owner),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'billable': value.billable,
        'subject': value.subject,
        'subject_freetext': value.subjectFreetext,
        'last_updated_user_type': value.lastUpdatedUserType,
        'initial_recipient_type': value.initialRecipientType,
    };
}

/**
* @export
* @enum {string}
*/
export enum ConversationSummaryCoreStateEnum {
    Unstarted = 'unstarted',
    Draft = 'draft',
    Active = 'active',
    Archived = 'archived',
    Deleted = 'deleted',
    Suspended = 'suspended'
}
/**
* @export
* @enum {string}
*/
export enum ConversationSummaryCoreCategoryEnum {
    Clinical = 'clinical',
    Preventive = 'preventive',
    Messaging = 'messaging'
}
/**
* @export
* @enum {string}
*/
export enum ConversationSummaryCoreTypeEnum {
    Member = 'member',
    Ctm = 'ctm'
}
/**
* @export
* @enum {string}
*/
export enum ConversationSummaryCoreSubjectEnum {
    MedicationQuestion = 'medication_question',
    SchedulingQuestion = 'scheduling_question',
    VisitFollowUp = 'visit_follow_up',
    TestResults = 'test_results',
    Referral = 'referral',
    Imaging = 'imaging',
    Immunizations = 'immunizations',
    MedicalRecords = 'medical_records',
    CovidConcerns = 'covid_concerns',
    VisionServices = 'vision_services',
    SomethingElse = 'something_else',
    PaymentQuestions = 'payment_questions',
    HealthMaintenanceReminder = 'health_maintenance_reminder',
    Outreach = 'outreach'
}
/**
* @export
* @enum {string}
*/
export enum ConversationSummaryCoreLastUpdatedUserTypeEnum {
    Member = 'member',
    Ctm = 'ctm'
}
/**
* @export
* @enum {string}
*/
export enum ConversationSummaryCoreInitialRecipientTypeEnum {
    Ctm = 'ctm',
    CareTeam = 'care_team'
}


