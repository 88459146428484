// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatorScoreOutput,
    CalculatorScoreOutputFromJSON,
    CalculatorScoreOutputToJSON,
} from './CalculatorScoreOutput';


/**
 * 
 * @export
 * @interface CalculatorHistory
 */
export interface CalculatorHistory  {
    /**
     * 
     * @type {string}
     * @memberof CalculatorHistory
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorHistory
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalculatorHistory
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CalculatorHistory
     */
    submittedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalculatorHistory
     */
    acknowledgedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalculatorHistory
     */
    resolvedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CalculatorHistory
     */
    resolvedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorHistory
     */
    acknowledgedBy?: string;
    /**
     * 
     * @type {Array<CalculatorScoreOutput>}
     * @memberof CalculatorHistory
     */
    results?: Array<CalculatorScoreOutput>;
}

export function CalculatorHistoryFromJSON(json: any): CalculatorHistory {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'submittedAt': !exists(json, 'submitted_at') ? undefined : new Date(json['submitted_at']),
        'acknowledgedAt': !exists(json, 'acknowledged_at') ? undefined : new Date(json['acknowledged_at']),
        'resolvedAt': !exists(json, 'resolved_at') ? undefined : new Date(json['resolved_at']),
        'resolvedBy': !exists(json, 'resolved_by') ? undefined : json['resolved_by'],
        'acknowledgedBy': !exists(json, 'acknowledged_by') ? undefined : json['acknowledged_by'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(CalculatorScoreOutputFromJSON),
    };
}

export function CalculatorHistoryToJSON(value?: CalculatorHistory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'time_sensitive': value.timeSensitive,
        'submitted_at': value.submittedAt === undefined ? undefined : value.submittedAt.toISOString(),
        'acknowledged_at': value.acknowledgedAt === undefined ? undefined : value.acknowledgedAt.toISOString(),
        'resolved_at': value.resolvedAt === undefined ? undefined : value.resolvedAt.toISOString(),
        'resolved_by': value.resolvedBy,
        'acknowledged_by': value.acknowledgedBy,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(CalculatorScoreOutputToJSON),
    };
}


