import React, {
  FunctionComponent,
  useState,
  useEffect,
  DetailedHTMLProps,
  ImgHTMLAttributes,
} from "react";
import { useSelector } from "react-redux";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { State } from "../../@types";
import { getAuthorizedAssetUrl, handleAttachments } from "./utils";
import { a11yClick } from "utils/a11yClick";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";

interface OwnProps
  extends DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  type?: "file-viewer" | "file" | "pdf" | "image" | "background-image";
  className?: string;
  src: string;
  alt?: string;
  defaultImage?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  tabIndex?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    bgImageStyle: {
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "inline-block",
      marginRight: "10px",
      backgroundImage: (props: any) => `url(${props.imageUrl})`,
    },
  })
);

export const AuthorizedImage: FunctionComponent<OwnProps> = (
  props
): JSX.Element => {
  const {
    type,
    className,
    src,
    alt = "",
    defaultImage,
    onClick,
    style,
    tabIndex,
  } = props;
  const [error, setError] = useState(false);
  const [preloadedImageSrc, setPreloadedImageSrc] = useState("");
  const accessToken = useSelector((state: State) => state.auth.accessToken);
  const { isApp } = selectNativeAppState();

  const classes = useStyles({ imageUrl: preloadedImageSrc || defaultImage });

  const imageSource =
    error && defaultImage
      ? getAuthorizedAssetUrl(defaultImage, accessToken)
      : getAuthorizedAssetUrl(src, accessToken);

  useEffect(() => {
    if (type === "background-image") {
      const preloaderImage = document.createElement("img");
      const setErrorToTrue = () => setError(true);
      const setImageSrc = () => setPreloadedImageSrc(preloaderImage.src);

      preloaderImage.src = imageSource;
      preloaderImage.addEventListener("error", setErrorToTrue);
      preloaderImage.addEventListener("load", setImageSrc);

      return () => {
        preloaderImage.removeEventListener("error", setErrorToTrue);
        preloaderImage.removeEventListener("load", setImageSrc);
      };
    }
    return undefined;
  }, [type]);

  switch (type) {
    case "file-viewer":
      return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          tabIndex={tabIndex}
          className={className}
          data-e2e={props["data-e2e"]}
          data-testid={props["data-testid"]}
          onClick={onClick}
          onKeyPress={(e) => {
            if (typeof onClick === "function") {
              a11yClick(e) && onClick();
            }
          }}
        >
          {props.children}
        </div>
      );
    case "file":
      return (
        <a
          tabIndex={tabIndex}
          className={className}
          href={imageSource}
          target="_blank"
          rel="noopener noreferrer"
          download={!isApp}
          onClick={() => {
            handleAttachments(Boolean(isApp), imageSource);
          }}
          data-e2e={props["data-e2e"]}
          data-testid={props["data-testid"]}
        >
          {props.children}
        </a>
      );
    case "pdf":
      return (
        <a
          tabIndex={tabIndex}
          className={className}
          href={imageSource}
          target="_blank"
          rel="noopener noreferrer"
          download={!isApp}
          data-e2e={props["data-e2e"]}
          data-testid={props["data-testid"]}
        >
          {props.children}
        </a>
      );
    case "background-image":
      return (
        // TODO: If div role is dynamic, pass as props and add this to the element
        // when an onClick function is also passed as props, then remove next line
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          data-e2e={props["data-e2e"]}
          data-testid={props["data-testid"]}
          aria-label={alt}
          className={classes.bgImageStyle}
          style={style}
          onClick={onClick}
          // when the role is set properly on this element the next line can be removed
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex={onClick && !tabIndex ? 0 : -1}
          onKeyPress={(e) => {
            if (typeof onClick === "function") {
              a11yClick(e) && onClick();
            }
          }}
        />
      );
    case "image":
    default:
      return (
        // TODO: Image role is dynamic, pass as props and add this to the image element
        // when an onClick function is also passed as props, then remove next line
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img
          alt={alt}
          className={`authorized-image ${className}`}
          src={imageSource}
          onError={() => setError(true)}
          onClick={onClick}
          data-e2e={props["data-e2e"]}
          data-testid={props["data-testid"]}
          onKeyPress={(e) => {
            if (typeof onClick === "function") {
              a11yClick(e) && onClick();
            }
          }}
        />
      );
  }
};

export default AuthorizedImage;
